import React, { useState } from 'react';
import Breadcrumb from 'components/ui/Breadcrumb';
import { Box, Button, Card, CardContent, Grid, IconButton, LinearProgress, Pagination, Skeleton } from '@mui/material';
import { Add as AddIcon, Print as PrintIcon } from '@mui/icons-material';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ListProvider from 'components/ui/ListProvider';
import EditButton from 'components/ui/buttons/EditButton';
import {
    downloadQuotationPdfRequest,
    getQuotationExcelRequest,
    getQuotationsListRequest,
    quotationSegmentOptionsList,
    quotationStatusOptionsList
} from '../../services/quotation';
import QuotationCommentHistoryModal from '../../components/quotation/QuotationCommentHistoryModal';
import { downloadFileFromResponse } from '../../utils';
import TextFilter from '../../components/ui/list-filters/TextFilter';
import SelectFilter from '../../components/ui/list-filters/SelectFilter';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import { getIncentiveEntryExcelRequest } from '../../services/incentive_entry';
import ExcelButton from '../../components/ui/buttons/ExcelButton';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function QuotationListPage(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({
        search: {
            name: '',
            status: {
                id: 1,
                status: 'Pending'
            }
            // status: null
        },
        sortParams: {
            column: 'name',
            order: 'asc'
        }
    });

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.QUOTATION });

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 4,
            minWidth: 150
        },
        {
            field: 'contactNo',
            headerName: 'Address & Phone No.',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'segment',
            headerName: 'Segment',
            flex: 1,
            minWidth: 80,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.segment?.segment}
                </Box>
            )
        },
        {
            field: 'creationDate',
            headerName: 'Creation Date',
            flex: 2,
            minWidth: 50
        },
        {
            field: 'assignTo',
            headerName: 'Assigned To',
            flex: 1,
            minWidth: 80,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.assignTo?.name}
                </Box>
            )
        },
        {
            field: 'followupDate',
            headerName: 'Followup Date',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'comment_history',
            flex: 2,
            headerName: 'Comment History',
            cellClassName: 'text-center',
            minWidth: 140,
            renderCell: ({ row }) => (
                <Box>
                    <QuotationCommentHistoryModal quotationId={row.id} />
                </Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            minWidth: 100,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.status?.status}
                </Box>
            )
        },
        {
            field: 'id',
            flex: 2,
            headerName: 'Actions',
            cellClassName: 'text-center',
            minWidth: 140,
            renderCell: ({ row }) => (
                <Box>
                    <EditButton
                        color="info"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => navigate(`${appUrls.QUOTATION_EDIT}/${row.id}`)}
                    />
                    <IconButton
                        color="info"
                        aria-label="download"
                        onClick={async () => {
                            const data = { id: row.id };
                            const payload = createPayload(data);
                            const result = await downloadQuotationPdfRequest(payload);
                            console.log('-> result', result);
                            if (!result.error) {
                                downloadFileFromResponse(result, `quotation.pdf`);
                            }
                        }}
                    >
                        <PrintIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    const handleExcelDownload = async (data) => {
        const body = _.cloneDeep(data);
        const payload = createPayload(body);
        const result = await getQuotationExcelRequest(payload);
        if (!result.error) {
            downloadFileFromResponse(result, 'quotations', 'xlsx', true);
        }
    };

    const fetchQuotationsList = async (data) => {
        const payload = createPayload(data);
        return getQuotationsListRequest(payload);
    };

    return (
        <>
            <Breadcrumb title="Quotations" help_code='quotation_list'/>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ListProvider fetcher={fetchQuotationsList} initialData={initialData}>
                                {(listProps) => (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="space-between" spacing={1}>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <Box
                                                        sx={{
                                                            pb: 1,
                                                            pl: 1,
                                                            pr: {
                                                                xs: 3,
                                                                sm: 0
                                                            }
                                                        }}
                                                    >
                                                        <TextFilter name="name" label="Search" listProps={listProps} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <SelectFilter
                                                        listProps={listProps}
                                                        name="status"
                                                        valueKey="id"
                                                        labelKey="status"
                                                        options={quotationStatusOptionsList}
                                                        label="Status"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <SelectFilter
                                                        listProps={listProps}
                                                        name="segment"
                                                        valueKey="id"
                                                        labelKey="segment"
                                                        options={quotationSegmentOptionsList}
                                                        label="Segment"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="flex-end"
                                                        sx={{
                                                            justifyContent: {
                                                                xs: 'center',
                                                                md: 'flex-end'
                                                            }
                                                        }}
                                                    >
                                                        <ExcelButton
                                                            onClick={() => {
                                                                handleExcelDownload(listProps);
                                                            }}
                                                        />
                                                        <Button
                                                            className={classes.button}
                                                            variant="outlined"
                                                            color="primary"
                                                            sx={{
                                                                mr: 1
                                                            }}
                                                            onClick={() => navigate(appUrls.QUOTATION_ADD)}
                                                            disableElevation
                                                            startIcon={<AddIcon />}
                                                        >
                                                            Add Quotation
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                p={1}
                                                style={{
                                                    // height: 500,
                                                    width: '100%'
                                                }}
                                            >
                                                <Box className={classes.progressBarContainer}>
                                                    {listProps.loading && <LinearProgress />}
                                                </Box>
                                                {listProps.listItems && !listProps.loading && (
                                                    <>
                                                        <DataGrid
                                                            rows={listProps.listItems}
                                                            columns={columns}
                                                            pagination={false}
                                                            paginationMode="server"
                                                            pageSize={listProps.limit}
                                                            page={listProps.page}
                                                            row
                                                            autoHeight
                                                            disableColumnMenu
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                mt: 2,
                                                                mb: 0,
                                                                pb: 0
                                                            }}
                                                        >
                                                            <Pagination
                                                                color="primary"
                                                                count={listProps.totalPages}
                                                                page={listProps.page}
                                                                onChange={(e, value) => {
                                                                    console.log('-> value', value);

                                                                    listProps.setPage(value);
                                                                }}
                                                            />
                                                        </Box>
                                                    </>
                                                )}
                                                {listProps.initialLoading && (
                                                    <Grid container spacing={1}>
                                                        {[...Array(12)].map((_, i) => (
                                                            <Grid key={i} item xs={12}>
                                                                <Skeleton variant="rect" height={40} />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </ListProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default QuotationListPage;
