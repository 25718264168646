import axios from 'axios';
import { extractDataFromResponse, parseApiErrorResponse } from './apiUtils';

// eslint-disable-next-line import/prefer-default-export
export const callApiThunk = async ({
    requestFunction,
    successCode = 200,
    showToastOnSuccess = false,
    showToastOnError = true,
    logOutOnAuthError = true,
    authErrorCode = 401,
    thunkApi,
    useAuth = true
}) => {
    if (thunkApi) {
        let axiosInstance;
        if (useAuth) {
            const accessToken = thunkApi.getState().tokens.accessToken;
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            };
            axiosInstance = axios.create({
                headers
            });
        } else {
            axiosInstance = axios;
        }

        if (requestFunction) {
            try {
                const response = await requestFunction(axiosInstance);
                return extractDataFromResponse({
                    response,
                    successCode,
                    showSuccessToast: showToastOnSuccess,
                    showErrorToast: showToastOnError
                });
            } catch (error) {
                if (error.response) {
                    if (error.response.status === authErrorCode && logOutOnAuthError) {
                        thunkApi.dispatch({
                            type: 'auth/logout/rejected',
                            payload: {}
                        });
                    }

                    return parseApiErrorResponse({
                        error,
                        showToast: showToastOnError
                    });
                }
                return { error: true };
            }
        }
        return { error: true };
    }
    return { error: true, message: 'Thunk Api is required' };
};
