import React, { useCallback, useEffect } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import SelectField from '../../libs/formik_fields/SelectField';
import {
    getQuotationDefaultSummaryRequest,
    quotationCustomerTitleList,
    quotationSegmentOptionsList,
    quotationStatusOptionsList
} from '../../services/quotation';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import DateInputField from '../../libs/formik_fields/DateInputField';
import appModules from '../../appModules';
import { FieldArray } from 'formik';
import RateListModal from '../vehicle/RateListModal';
import StatusSwitchField from '../../libs/formik_fields/StatusSwitchField';
import { Delete as DeleteIcon } from '@mui/icons-material';
import useFetchSelectOptions from 'hooks/useFetchSelectOptions';
import { getActiveUsersListForJobCardProcess } from 'services/job_card';
import { width } from '@mui/system';
import { mt } from 'date-fns/locale';

const warrantyOptionsList = [
    { id: 'Unconditional warranty for Upto 2 years/50% tread life, followed by a 3-year standard warranty', warranty: 'Unconditional warranty for Upto 2 years/50% tread life, followed by a 3-year standard warranty' },
    { id: 'Unconditional warranty for Upto 3 years/50% tread life, followed by a 3-year standard warranty', warranty: 'Unconditional warranty for Upto 3 years/50% tread life, followed by a 3-year standard warranty' },
    { id: 'Standard Manufacturing defect warranty for 3 Years', warranty: 'Standard Manufacturing defect warranty for 3 Years' },
    { id: 'Standard Manufacturing defect warranty for 5 Years', warranty: 'Standard Manufacturing defect warranty for 5 Years' },

];

const emptyQuotation = {
    segment: null,
    title: '',
    name: '',
    contactNo: '',
    subject: '',
    address: '',
    summary: '',
    followupDate: new Date(),
    assignTo: null,
    quotationDetail: [],
    status: null,
    remark: ''
};

const quotationSchema = yup.object().shape({
    segment: yup.mixed().required('Please select segment.'),
    title: yup.mixed().required('Please select title.'),
    name: yup.string().required('Please enter name.'),
    subject: yup.string().required('Please enter name.'),
    summary: yup.string().required('Please enter name.'),
    followupDate: yup.mixed().required('Please enter followup date.'),
    assignTo: yup.mixed().required('Please select assigned to.'),
    contactNo: yup.string().required('Please enter contact Number.'),
    remark: yup.string('Please enter valid remark.'),
    quotationDetail: yup
        .array()
        .of(
            yup.object().shape({
                size: yup.string().required('Size is required.'),
                brand: yup.string().required('Brand is required.'),
                make: yup.string().required('Variant is required.'),
                price: yup.string().required('Price is required.'),
                quantity: yup.string().required('Quantity is required.'),
                warranty: yup.mixed().required('Warranty is required.')
            })
        )
        .min(1, 'Please add at least one item.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const QuotationFormContent = (formik) => {
    const { isSubmitting, values, errors, setFieldValue } = formik;
    const { editMode } = values;
    console.log('-> errors', errors);

    const navigate = useNavigate();
    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.QUOTATION });
    const { options: activeOperatorsOptions, loading: activeOperatorsOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = payloadCreator({});
            return getActiveUsersListForJobCardProcess(payload);
        }
    });
    useEffect(() => {
        async function fetchData() {
            if (!editMode) {
                const payload = payloadCreator({});
                const result = await getQuotationDefaultSummaryRequest(payload);
                console.log('-> result', result);
                if (result.error === false) {
                    setFieldValue('summary', result.summary);
                }
            }
        }
        fetchData();
    }, [!!editMode]);

    const handleBack = useCallback(() => {
        navigate(appUrls.QUOTATION_LIST);
    }, [navigate]);

    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12} md={6} px={1}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <SelectField
                            name="segment"
                            placeholder="Segment"
                            valueKey="id"
                            labelKey="segment"
                            options={quotationSegmentOptionsList}
                            isDisabled={editMode}
                        />
                    </Grid>
                    <Grid itemx xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <SelectField
                                    name="title"
                                    placeholder="Title*"
                                    valueKey="id"
                                    labelKey="value"
                                    options={quotationCustomerTitleList}
                                    isDisabled={editMode}
                                />
                                {/* <TextField name="title" label="Title*" disabled={editMode}/> */}
                            </Grid>
                            <Grid item xs={9}>
                                <TextField name="name" label="Name*" disabled={editMode} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="subject" label="Subject*" disabled={editMode} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <TextField name="contactNo" label="Address & Phone No*" disabled={editMode} multiline rows={4} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <TextField name="summary" label="Summary Text (Max 13 Lines)*" multiline rows={15} disabled={editMode} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FieldArray
                    name="quotationDetail"
                    render={(arrayHelpers) => (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography m={0} variant="h3">
                                    Quotation Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            sx={{
                                                ml: 1
                                            }}
                                            onClick={() => {
                                                arrayHelpers.push({
                                                    size: ' ',
                                                    brand: '',
                                                    make: '',
                                                    price: 0,
                                                    quantity: 0,
                                                    warranty: null,
                                                    isNew: true
                                                });
                                            }}
                                            disabled={editMode}
                                        >
                                            Add Item
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} pb={2}>
                                <TableContainer sx={{ maxHeight: 440,overflow: 'visible' }}>
                                    <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >Sno</TableCell>
                                                <TableCell align="center">Size</TableCell>
                                                <TableCell align="center">Brand</TableCell>
                                                <TableCell align="center">Variant</TableCell>
                                                <TableCell align="center">Price</TableCell>
                                                <TableCell align="center">Quantity</TableCell>
                                                <TableCell align="center">Warranty</TableCell>
                                                {editMode || <TableCell align="left">Actions</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{zIndex:"-999999999999999999"}}>
                                            {values.quotationDetail.map((row, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell valign="top" align="center">
                                                        <TextField
                                                            name={`quotationDetail["${index}"].size`}
                                                            showErrorText={false}
                                                            disabled={editMode}
                                                            sx={{width:"150px"}}
                                                            
                                                        />
                                                    </TableCell>
                                                    <TableCell valign="top" align="center">
                                                        <TextField
                                                            name={`quotationDetail["${index}"].brand`}
                                                            showErrorText={false}
                                                            disabled={editMode}
                                                        />
                                                    </TableCell>
                                                    <TableCell valign="top" align="center">
                                                        <TextField
                                                            name={`quotationDetail["${index}"].make`}
                                                            showErrorText={false}
                                                            disabled={editMode}
                                                        />
                                                    </TableCell>
                                                    <TableCell valign="top" align="center">
                                                        <TextField
                                                            name={`quotationDetail["${index}"].price`}
                                                            showErrorText={false}
                                                            disabled={editMode}
                                                        />
                                                    </TableCell>
                                                    <TableCell valign="top" align="center">
                                                        <TextField
                                                            name={`quotationDetail["${index}"].quantity`}
                                                            showErrorText={false}
                                                            disabled={editMode}
                                                        />
                                                    </TableCell>
                                                    
                                                    <TableCell valign="top" align="center" >
                                                    <SelectField
                                                            name={`quotationDetail["${index}"].warranty`}
                                                            placeholder="Warranty"
                                                            valueKey="id"
                                                            labelKey="warranty"
                                                            menuPlacement="top"
                                                            options={warrantyOptionsList}
                                                            sx={{width:"200px", mt:3}}
                                                            isDisabled={editMode}
                                                        />
                                                    </TableCell>
                                                    {editMode || (
                                                        <TableCell valign="top" align="left">
                                                            {row.isNew && (
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    color="error"
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )}
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                            {values.quotationDetail.length === 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        Please add at least one item
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )}
                />
            </Grid>

            <Grid item xs={12} pt={1}>
                <Grid container>
                    <Grid itemx xs={12} md={3} px={1}>
                        <SelectField
                            name="status"
                            placeholder="Status"
                            valueKey="id"
                            labelKey="status"
                            options={quotationStatusOptionsList}
                            menuPlacement="top"
                        />
                    </Grid>
                    <Grid itemx xs={12} md={3} px={1}>
                        <TextField name="remark" label="Status Remark" />
                    </Grid>
                    <Grid itemx xs={12} md={3}>
                        <SelectField
                            name="assignTo"
                            placeholder="Assigned To*"
                            valueKey="id"
                            labelKey="name"
                            menuPlacement="top"
                            options={activeOperatorsOptions}
                            // isDisabled={editMode}
                        />
                    </Grid>
                    <Grid itemx xs={12} md={3} px={1}>
                        <DateInputField name="followupDate" label="Followup Date*" fullWidth />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} pt={2}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            loading={isSubmitting}
                            loadingPosition="center"
                        >
                            Save
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                                ml: 1
                            }}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

function QuotationForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyQuotation}
            onSubmit={handleSubmit}
            validationSchema={quotationSchema}
            FormContent={QuotationFormContent}
        />
    );
}

export default QuotationForm;
