export const helpdata = [
    {
        title: 'Add Tyre brand',
        detail: 'Add Tyre brand, Add Tyre brand,Add Tyre brand',
        youtube_link: 'https://youtube.com',
        tags:'Tyre brand,Add Tyre Brand'
    },
    {
        title: 'Edit Tyre brand',
        detail: 'Edit Tyre brand, Edit Tyre brand,Edit Tyre brand',
        youtube_link: 'https://youtube.com',
        tags:'Tyre brand,Edit Tyre Brand'
    },
    {
        title: 'Add Tyre Size',
        detail: 'Add Tyre Size, Add Tyre Size,Add Tyre Size',
        youtube_link: 'https://youtube.com',
        tags:'Tyre Size,Add Tyre Size'
    },
    {
        title: 'Edit Tyre Size',
        detail: 'Edit Tyre Size, Edit Tyre Size,Edit Tyre Size',
        youtube_link: 'https://youtube.com',
        tags:'Tyre Size,Edit Tyre Size'
    },
    {
        title: 'Add Vehicle',
        detail: 'Add Vehicle, Add Vehicle,Add Vehicle',
        youtube_link: 'https://youtube.com',
        tags:'Vehicle,Add Vehicle,Car'
    },
    {
        title: 'Edit Vehicle',
        detail: 'Edit Vehicle, Edit Vehicle,Edit Vehicle',
        youtube_link: 'https://youtube.com',
        tags:'Vehicle,Edit Vehicle,Car'
    },
    ];
