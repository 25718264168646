import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Divider,
  Grid,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { helpdata } from 'data/helpdata';
import { useTheme, styled } from '@mui/material/styles';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Refresh, YouTube } from '@mui/icons-material';
import appModules from 'appModules';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import { callbackRequestRequest } from 'services/user';
import { useSelector } from 'react-redux';
import { selectUserEmail, selectUserName } from 'store/slices/authSlice';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));


const CallbackRequestModal = ({ isOpen, onClose }) => {
    const theme = useTheme();
    const username = useSelector(selectUserName);
    const email = useSelector(selectUserEmail);
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileNumberDisable, setMobileNumberDisable] = useState(false);
  const [otp, setOtp] = useState('');
  const [serverOtp, setServerOtp] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.DASHBOARD });

  const handleClose = () => {
    // setSearchTerm('');
    
    setOtp('');
    setMobileNumber('');
    setShowOtp(false)
    setMobileError(false);
    setOtpError(false);
    setMobileNumberDisable(false);
    setShowSuccessMsg(false);
    onClose();
  };

  const handleMobileChange = (event) => {
    setMobileNumber(event.target.value);
  };
  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSendCallbackRequest = async() => {
    setMobileError(false);
    if(!mobileNumber.match(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/)){
      setMobileError(true);
      return false;
    }
    setMobileNumberDisable(true);
     const data = {
                mobile:mobileNumber
            };
    const payload = payloadCreator(data);
    const response = await callbackRequestRequest(payload);
    if (response.error === false) {
      console.log(response)
      if(response.resData === 'no-otp'){
        setShowSuccessMsg(true)
      }else{
         setServerOtp(response.resData)
         setShowOtp(true)
      }
    }
  };
  
  const handleConfirmCallbackRequest = async () => {
setOtpError(false)
    if(otp != serverOtp){
       setOtpError(true); 
       return false;
    }
    const data = {
      mobile:mobileNumber,
      otp:'OK'
  };
      const payload = payloadCreator(data);
      const response = await callbackRequestRequest(payload);
      if (response.error === false) {
      
      setShowSuccessMsg(true)
      }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <>
      {!showSuccessMsg && <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '5px',
          width:'50%',
          height:'60%'
        }}
      >
        <Typography variant='h3'>Raise Callback request</Typography>
        <IconButton onClick={handleClose} style={{ position: 'absolute', top: 10, right: 10 }}>
          <CloseIcon />
        </IconButton>
        <Divider sx={{mt:2}}/>
        <><TextField
         sx={{mt:2}}
          label="Mobile Number"
          variant="outlined"
          fullWidth
          value={mobileNumber}
          onChange={handleMobileChange}
          disabled ={mobileNumberDisable}
        />
        <Typography variant='body2'>This mobile number will be updated on your profile for future conversation.</Typography>
       {mobileError && <Typography variant='body' sx={{display:'flex'}}>Please enter a valid 10 didgit mobile number.</Typography> }
        </>
        {showOtp && <><TextField
         sx={{mt:2}}
          label="OTP"
          variant="outlined"
          fullWidth
          value={otp}
          onChange={handleOtpChange}
        />
        <Typography variant='body2'>An otp is sent to you registered email id ({email}). Please check.</Typography>
     
        {otpError && <Typography variant='body' sx={{display:'flex'}}>Incorrect OTP.</Typography> }
        </>}
        {!showOtp && 
        <Button sx={{mt:2}} variant="contained" disableElevation onClick={handleSendCallbackRequest}>
            Send Callback Request
        </Button>}
        {showOtp && 
        <Button sx={{mt:2}} variant="contained" disableElevation onClick={handleConfirmCallbackRequest}>
            Confirm Callback Request
        </Button>}
      </div>}
      {showSuccessMsg &&
      <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        width:'50%',
        height:'40%'
      }}
    >
      <Typography variant='h3'>Callback request submitted successfully</Typography>
      <IconButton onClick={handleClose} style={{ position: 'absolute', top: 10, right: 10 }}>
        <CloseIcon />
      </IconButton>
      <Divider sx={{mt:2}}/>

        <Typography variant='body2'>Dear {username}, </Typography>

        <Typography variant='body2' sx={{pt:2}}>Thank you for contacting us. Your request has been received and we will be in touch shortly.</Typography>

        <Typography variant='h4' sx={{pt:2}}>Sincerely,</Typography>
        <Typography variant='h4'>The Tyreworkshop Portal Team</Typography>

      </div>
      }
      </>
    </Modal>
  );
};

export default CallbackRequestModal;