import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import ComplaintForm from '../../components/complaint/ComplaintForm';
import { getComplaintDetailsRequest, saveComplaintRequest } from '../../services/complaints';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ComplaintCommentHistoryList from '../../components/complaint/ComplaintCommentHistoryList';
import { getQuotationDetailsRequest, saveQuotationRequest } from '../../services/quotation';
import QuotationForm from '../../components/quotation/QuotationForm';
import QuotationCommentHistoryList from '../../components/quotation/QuotationCommentHistoryList';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function QuotationEditPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.COMPLAINT });
    const { quotationId } = useParams();

    const [quotationDetails, setQuotationDetails] = useState();
    const [quotationDetailsLoading, setQuotationDetailsLoading] = useState(true);

    useEffect(() => {
        async function fetchQuotationDetails() {
            setQuotationDetailsLoading(true);
            const payload = payloadCreator({ id: quotationId });
            const result = await getQuotationDetailsRequest(payload);
            if (result.error === false) {
                const { error, ...data } = result;
                result.quotationDetail.forEach((qd) => {
                    qd.warranty = {"id" : qd.warranty,"warranty" : qd.warranty}
                    });
                setQuotationDetails({
                    ...data,
                    title: data.title ? { id: data.title, value: data.title } : null,
                    followupDate: moment(result.followupDate, 'DD/MM/YYYY').toDate(),
                    
                    editMode: true
                });
            }
            setQuotationDetailsLoading(false);
        }

        fetchQuotationDetails();
    }, [quotationId]);

    const navigate = useNavigate();

    const handleEditQuotation = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { segment, title, name, contactNo, subject, address, summary, assignTo, followupDate, status, remark, quotationDetail } =
            values;
        quotationDetail.forEach((qd) => {
            qd.warranty = qd.warranty.warranty
            });
        const data = {
            id: quotationId,
            segment,
            title: title?.value ?? '',
            name,
            contactNo,
            subject,
            address,
            summary,
            remark,
            followupDate: moment(followupDate).format('DD-MM-YYYY'),
            assignTo,
            quotationDetail,
            status
        };
        const payload = payloadCreator(data);
        const response = await saveQuotationRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.QUOTATION_LIST);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Quotation" help_code='quotation_edit'/>
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {quotationDetailsLoading || (
                                            <QuotationForm initialValues={quotationDetails} handleSubmit={handleEditQuotation} />
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <QuotationCommentHistoryList quotationId={quotationId} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default QuotationEditPage;
