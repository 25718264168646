// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { markTaskDoneRequest } from 'services/user';
import { useDispatch } from 'react-redux';
import { getUserInfo } from 'store/slices/authSlice';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({notifications,setOpen}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.DASHBOARD });
    const markDone = async (code) => {
        const payload = createPayload({ code });
        const result = await markTaskDoneRequest(payload);
        if (result.error === false) {
            //refreshJobCardsList();
            dispatch(getUserInfo());
        }
    };

    const navegateAndClose = (path) => {
        navigate(path);
        setOpen(false);
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            
            {notifications.map((notification,index)=>
<><Divider/>
<ListItemWrapper key={index}>
                <ListItem alignItems="center">
                    {/* <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: theme.palette.primary.dark,
                                backgroundColor: theme.palette.primary.light,
                                border: 'none',
                                borderColor: theme.palette.primary.main
                            }}
                        >
                            <Settings stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar> */}
                    <ListItemText primary={<Typography variant="subtitle1">{notification.description}</Typography>} />
                    {/* <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {notification.code}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction> */}
                </ListItem>
                <Grid container direction="column" >
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">{notification.detail}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Button variant="contained" disableElevation onClick={() => navegateAndClose(notification.actionpath)}>
                                    Do it now
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" disableElevation onClick={() => markDone(notification.code)}>
                                    Mark Done
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
</>
            )}
            
            
            
        </List>
    );
};

export default NotificationList;
