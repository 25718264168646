import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import TyreForm from '../../../components/tyre/TyreForm';
import { pickProperties } from '../../../utils';
import { saveTyreRequest } from '../../../services/tyre';
import { useSelector } from 'react-redux';
import { selectIsServiceActive } from '../../../store/slices/serviceSlice';
import appServices from '../../../appServices';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddTyrePage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [tyrePayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.TYRE });

    const isTyreStockServiceActive = useSelector(selectIsServiceActive(appServices.TYRE_STOCK_MANAGEMENT));

    const navigate = useNavigate();

    // eslint-disable-next-line consistent-return
    const handleAddTyre = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { name, description, status, tyreBrand, stock } = values;
        if (isTyreStockServiceActive) {
            if (stock.length < 1) {
                setSubmitting(false);
                return toast.error('Please add atleast one stock item.');
            }
        }
        const data = {
            name,
            description,
            status: {
                id: status
            },
            tyreBrand: pickProperties(tyreBrand, ['id']),
            stock: stock.map((item) => ({
                size: pickProperties(item.size, ['id']),
                status: { id: item.status },
                stock: Number(item.stock),
                price: Number(item.price)
            }))
        };
        const payload = tyrePayloadCreator(data);
        const response = await saveTyreRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.TYRE);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Add Tyre" help_code='tyre_add'/>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <TyreForm handleSubmit={handleAddTyre} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddTyrePage;
