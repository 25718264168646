import React from 'react';
import Select from 'react-select';
import { selectStyles } from '../../../libs/formik_fields/SelectField';

function updateSearch(val,listProps,name,dependentSelect,dependentSelectRefresh){
    listProps.setSearch({ [name]: val })
    if(dependentSelect){
        dependentSelectRefresh(val)
    }
}

function SelectFilter({ listProps, options, name, label, labelKey = 'label', valueKey = 'value',isMulti,isClearable=true,dependentSelect=false,dependentSelectRefresh=false  }) {
    return (
        <Select
            styles={selectStyles}
            options={options}
            value={listProps?.search?.[name]}
            onChange={(val) => updateSearch(val,listProps,name,dependentSelect,dependentSelectRefresh)}
            placeholder={`Select ${label}`}
            label={label}
            getOptionLabel={(option) => option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            isMulti={!!isMulti}
            isClearable={!!isClearable}
        />
    );
}



export default SelectFilter;
