import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appUrls from 'appUrls';
import Breadcrumb from 'components/ui/Breadcrumb';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import swal from '@sweetalert/with-react';

import {
    getJobCardDetailsByIdForProcessRequest,
    jobCardCloseWithoutBillReason,
    processJobCardWithBillRequest,
    processJobCardWithoutBillRequest,
    saveJobCardRequest
} from 'services/job_card';
import ProcessJobCardForm from 'components/job-card/ProcessJobCardForm';
import { pickProperties } from '../../utils';

const extractValidOtherProductSales = (otherProductSales) =>
    otherProductSales?.filter((otherProductSale) => otherProductSale.product) ?? [];

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}));

// eslint-disable-next-line no-unused-vars
function ProcessJobCardPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.JOB_CARD });

    const navigate = useNavigate();

    const { jobCardId } = useParams();
    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            setDetailsLoading(true);
            const detailsReqPayload = payloadCreator({ id: jobCardId });
            const data = await getJobCardDetailsByIdForProcessRequest(detailsReqPayload);
            if (data.error === false) {
                const detailsObj = data;
                detailsObj.closewithoutSaleReasons = jobCardCloseWithoutBillReason.map((reason) => ({
                    reason: reason.id,
                    text: '',
                    enabled: false,
                    label: reason.value
                }));
                // if (detailsObj.customer) {
                //     detailsObj.customer.vehicleRegNo = {
                //         value: detailsObj.customer.vehicleRegNo,
                //         label: detailsObj.customer.vehicleRegNo
                //     };
                // }

                detailsObj.editMode = false;
                setDetails(detailsObj);
            }
            setDetailsLoading(false);
        };
        fetchDetails();
    }, [jobCardId]);

    const validateCloseWithoutBill = (values) => {
        const errors = [];
        if (!values.wheelTypeDTO) {
            errors.push(' Wheel Type is required');
        }
        if (!values.operator) {
            errors.push(' Operator is required');
        }

        if (
            values?.tyreTubeFitment?.enable ||
            values?.wheelBalancing?.enable ||
            values?.wheelAlignment?.enable ||
            values?.batteryHealthCheckup?.enable ||
            values?.headLightAlignment?.enable ||
            values?.n2?.enable ||
            values?.tyreRepair?.enable
        ) {
            errors.push('No service should be selecte in case of Close without Bill');
        }

        const enabledReasons = values.closewithoutSaleReasons.filter((reason) => reason.enable);
        if (enabledReasons.length === 0) {
            errors.push(' Atleast one reason is required');
        }

        const hasErrors = errors.length > 0;
        if (hasErrors) {
            swal({
                title: 'Errors!',
                icon: 'error',
                content: (
                    <ul style={{ textAlign: 'left' }}>
                        {errors.map((error) => (
                            <li>{error}</li>
                        ))}
                    </ul>
                )
            });
        }

        return {
            hasErrors,
            errors
        };
    };

    const validateCloseWithBill = (values) => {
        const errors = [];
        if (!values.wheelTypeDTO) {
            errors.push(' Wheel Type is required');
        }
        if (!values.operator) {
            errors.push(' Operator is required');
        }
        if (
            !values?.tyreTubeFitment?.enable &&
            !values?.wheelBalancing?.enable &&
            !values?.wheelAlignment?.enable &&
            !values?.batteryHealthCheckup?.enable &&
            !values?.headLightAlignment?.enable &&
            !values?.n2?.enable &&
            !values?.tyreRepair?.enable
        ) {
            errors.push(' Please select at least one service');
        }

        const enabledReasons = values.closewithoutSaleReasons.filter((reason) => reason.enable);
        // if (enabledReasons.length !== 0) {
        //     errors.push(' Please uncheck all closing without bill reasons');
        // }

        if (values?.tyreTubeFitment?.enable)
            if (!values.tyreTubeFitment?.amount || values.tyreTubeFitment.amount <= 0) {
                errors.push(' Invalid Tyre Tube Fitment amount.');
            }

        if (values?.wheelAlignment?.enable) {
            if (!values.wheelAlignment?.amount || values.wheelAlignment?.amount <= 0) {
                errors.push(' Wheel Alignment amount must be greater than 0');
            }
        }

        if (values?.batteryHealthCheckup?.enable) {
            if (!values.batteryHealthCheckup?.amount || values.batteryHealthCheckup?.amount <= 0) {
                errors.push(' Battery Health Checkup amount must be greater than 0');
            }
        }

        if (values?.headLightAlignment?.enable) {
            if (!values.headLightAlignment?.amount || values.headLightAlignment?.amount <= 0) {
                errors.push(' Head Light Alignment amount must be greater than 0');
            }
        }

        if (values?.n2?.enable) {
            if (!values.n2?.amount || values.n2?.amount <= 0) {
                errors.push(' N2 amount must be greater than 0');
            }
        }

        if (values?.tyreRepair?.enable) {
            if (!values.tyreRepair?.amount || values.tyreRepair?.amount <= 0) {
                errors.push(' Tyre Repair amount must be greater than 0');
            }
        }

        if (values?.wheelBalancing?.enable) {
            if (!values.wheelBalancing?.amount || values.wheelBalancing?.amount <= 0) {
                errors.push(' Wheel Balancing amount must be greater than 0');
            }

            if (values?.wheelBalancingWeight?.lf) {
                // if (!values.wheelBalancingWeight?.lfQty || values.wheelBalancingWeight?.lfQty <= 0) {
                //     errors.push(' Wheel Balancing LF weight qty must be greater than 0');
                // }
            } else if (!values?.wheelBalancingWeight?.lfReason) {
                errors.push(' Wheel Balancing LF  reason for not balancing is required');
            }

            if (values?.wheelBalancingWeight?.rf) {
                // if (!values.wheelBalancingWeight?.rfQty || values.wheelBalancingWeight?.rfQty <= 0) {
                //      errors.push(' Wheel Balancing RF weight qty must be greater than 0');
                // }
            } else if (!values?.wheelBalancingWeight?.rfReason) {
                errors.push(' Wheel Balancing RF  reason for not balancing is required');
            }

            if (values?.wheelBalancingWeight?.lr) {
                //   if (!values.wheelBalancingWeight?.lrQty || values.wheelBalancingWeight?.lrQty <= 0) {
                //      errors.push(' Wheel Balancing LR weight qty must be greater than 0');
                //   }
            } else if (!values?.wheelBalancingWeight?.lrReason) {
                errors.push(' Wheel Balancing LR  reason for not balancing is required');
            }

            if (values?.wheelBalancingWeight?.rr) {
                //   if (!values.wheelBalancingWeight?.rrQty || values.wheelBalancingWeight?.rrQty <= 0) {
                //       errors.push(' Wheel Balancing RR weight qty must be greater than 0');
                //   }
            } else if (!values?.wheelBalancingWeight?.rrReason) {
                errors.push(' Wheel Balancing RR  reason for not balancing is required');
            }

            if (values?.wheelBalancingWeight?.st) {
                //   if (!values.wheelBalancingWeight?.stQty || values.wheelBalancingWeight?.stQty <= 0) {
                //       errors.push(' Wheel Balancing ST weight qty must be greater than 0');
                //   }
            } else if (!values?.wheelBalancingWeight?.stReason) {
                errors.push(' Wheel Balancing RR  reason for not balancing is required');
            }
            if (!values.wheelBalancingWeight?.amount || values.wheelBalancingWeight?.amount <= 0) {
                //  errors.push(' Wheel Balancing total amount must be greater than 0');
            }
        }

        const validOtherProductItems = extractValidOtherProductSales(values.otherProductSales);

        if (validOtherProductItems.length > 0) {
            const amounts = validOtherProductItems.map((item) => !!item.amount);
            if (!amounts.every(Boolean)) {
                errors.push(' Other Product Sales amount must be greater than 0');
            }
        }

        const hasErrors = errors.length > 0;
        if (hasErrors) {
            swal({
                title: 'Errors!',
                icon: 'error',
                content: (
                    <ul style={{ textAlign: 'left' }}>
                        {errors.map((error) => (
                            <li>{error}</li>
                        ))}
                    </ul>
                )
            });
        }
        return {
            hasErrors,
            errors
        };
    };

    const handleProcessSubmit = async (values, { resetForm, setSubmitting }) => {
        if (values.submit_type === 'close_with_bill') {
            const payload = pickProperties(values, [
                'id',
                'jobCardNo',
                'customer',
                'odoMeterReading',
                'remark',
                'tyreTubeFitment',
                'wheelAlignment',
                'batteryHealthCheckup',
		        'headLightAlignment',
                'n2',
                'tyreRepair',
                'wheelBalancing',
                'wheelBalancingWeight',
                'otherProductSales',
                'closewithoutSaleReasons',
                'newTyreFitmentDTO',
                'wheelTypeDTO',
                'jobCardStatusDTO',
                'jobCardCreateDateTime',
                'operator'
            ]);
            const { hasErrors, errors } = validateCloseWithBill(payload);
            if (!hasErrors) {
                payload.otherProductSales = extractValidOtherProductSales(payload.otherProductSales);
                payload.closewithoutSaleReasons = values.closewithoutSaleReasons.filter((reason) => reason.enable);
                payload.closewithoutSaleReasons = payload.closewithoutSaleReasons.map(({ reason, text }) => ({
                    reason,
                    text
                }));
                const reqPayload = payloadCreator(payload);
                console.log('-> reqPayload', reqPayload);
                const response = await processJobCardWithBillRequest(reqPayload);
                if (response.error === false) {
                    resetForm();
                    navigate(appUrls.BILLING);
                }
                setSubmitting(false);
            }
        }
        if (values.submit_type === 'close_without_bill') {
            const { hasErrors } = validateCloseWithoutBill(values);
            if (!hasErrors) {
                setSubmitting(true);
                const {
                    id,
                    jobCardNo,
                    customer,
                    odoMeterReading,
                    remark,
                    tyreTubeFitment,
                    wheelAlignment,
                    batteryHealthCheckup,
		            headLightAlignment,
                    n2,
                    tyreRepair,
                    wheelBalancing,
                    wheelBalancingWeight,
                    otherProductSales,
                    closewithoutSaleReasons,
                    newTyreFitmentDTO,
                    wheelTypeDTO,
                    jobCardStatusDTO,
                    jobCardCreateDateTime,
                    operator
                } = values;
                const payloadData = {
                    id,
                    jobCardNo,
                    customer,
                    odoMeterReading,
                    remark,
                    tyreTubeFitment,
                    wheelAlignment,
                    batteryHealthCheckup,
		            headLightAlignment,
                    n2,
                    tyreRepair,
                    wheelBalancing,
                    wheelBalancingWeight,
                    otherProductSales,
                    closewithoutSaleReasons,
                    newTyreFitmentDTO,
                    wheelTypeDTO,
                    jobCardStatusDTO,
                    jobCardCreateDateTime,
                    operator
                };
                payloadData.closewithoutSaleReasons = values.closewithoutSaleReasons.filter((reason) => reason.enable);
                payloadData.closewithoutSaleReasons = payloadData.closewithoutSaleReasons.map(({ reason, text }) => ({
                    reason,
                    text
                }));
                payloadData.wheelTypeDTO = { id: payloadData?.wheelTypeDTO?.id };
                payloadData.operator = { id: payloadData?.operator?.id };
                payloadData.otherProductSales =
                    payloadData?.otherProductSales?.map(({ product, amount }) => ({
                        product,
                        amount
                    })) ?? [];

                const reqPayload = payloadCreator(payloadData);
                console.log('-> reqPayload', reqPayload);
                const response = await processJobCardWithoutBillRequest(reqPayload);
                if (response.error === false) {
                    resetForm();
                    navigate(appUrls.JOB_CARD);
                }
                setSubmitting(false);
            }
        }
        setSubmitting(false);
    };

    return (
        <>
 <Breadcrumb title="Process Job Card" help_code='job_card_process'/> 
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {!detailsLoading && details ? (
                                            <ProcessJobCardForm initialValues={details} handleSubmit={handleProcessSubmit} />
                                        ) : null}

                                        {detailsLoading && (
                                            <Grid container spacing={3}>
                                                {[...Array(8)].map((_, index) => (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <Skeleton variant="rect" height={40} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ProcessJobCardPage;
