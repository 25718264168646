import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBuildInfoRequest, getForgotPasswordRequest, getUserInfoRequest, logoutRequest } from '../../services/auth';
import { callApiThunk } from '../../utils/callApiThunk';

const LOGIN_URL = `${process.env.REACT_APP_API_BASE_URL}/authenticate`;
const REGISTER_URL = `${process.env.REACT_APP_API_BASE_URL}/user/register`;
const FORGOT_PASSWORD = `${process.env.REACT_APP_API_BASE_URL}/forgotpassword`;
const GET_USER_PROFILE_URL = `${process.env.REACT_APP_API_BASE_URL}/admin/users/get-user-profile`;

const initialState = {
    isAuthenticated: true,
    initialAuthLoading: true,
    authLoading: true,
    data: {},
    userRole: 'admin',
    accessToken: '',
    refreshToken: '',
    buildInfoLoading: true,
    buildInfo: {}
};

export const login = createAsyncThunk('auth/login', async (data, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    const { username, password } = data;

    const result = await callApiThunk({
        requestFunction: (axios) => axios.post(`${LOGIN_URL}`, { username, password }),
        successCode: 200,
        callRefreshTokenOnAuthError: false,
        thunkApi,
        useAuth: false
    });

    if (result.error === false) {
        return result;
    }
    return rejectWithValue(result);
});

export const register = createAsyncThunk('auth/login', async (data, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    const { name, email,site,mobile } = data.data;

    const result = await callApiThunk({
        requestFunction: (axios) => axios.post(`${REGISTER_URL}`, {data:{ name, email,site,mobile  }}),
        successCode: 200,
        callRefreshTokenOnAuthError: false,
        thunkApi,
        useAuth: false
    });

    if (result.error === false) {
        return result;
    }
    return rejectWithValue(result);
});
//
// export const logout = createAsyncThunk('auth/logout', async (data, { rejectWithValue, getState }) => {
//     const { accessToken, refreshToken } = getState().tokens;
//     const responseData = await logoutRequest({
//         accessToken,
//         refreshToken
//     });
//     if (responseData.error) {
//         rejectWithValue(responseData);
//     }
//     return responseData;
// });

export const getProfile = createAsyncThunk('auth/getUserProfile', async (data, thunkApi) => {
    const { rejectWithValue } = thunkApi;

    const result = await callApiThunk({
        requestFunction: (axios) => axios.get(`${GET_USER_PROFILE_URL}`),
        successCode: 200,
        callRefreshTokenOnAuthError: true,
        thunkApi,
        showToastOnSuccess: false,
        showToastOnError: false
    });

    if (result.error === false) {
        return result;
    }
    return rejectWithValue(result);
});

export const getBuildInfo = createAsyncThunk('auth/buildInfo', async (args, thunkApi) => {
    const data = await getBuildInfoRequest({ thunkApi });
    if (data.error === false) {
        return data;
    }
    return thunkApi.rejectWithValue(data);
});

export const forgotPassword = createAsyncThunk('auth/forgotpassword', async (data, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    const { site, email } = data;

    const result = await callApiThunk({
        requestFunction: (axios) => axios.post(`${FORGOT_PASSWORD}`, { site, email }),
        successCode: 200,
        callRefreshTokenOnAuthError: false,
        thunkApi,
        useAuth: false
    });

    if (result.error === false) {
        return result;
    }
    return rejectWithValue(result);
});

export const getUserInfo = createAsyncThunk('auth/userinfo', async (args, thunkApi) => {
    const data = await getUserInfoRequest({ thunkApi });
    if (data.error === false) {
        return data;
    }
    return thunkApi.rejectWithValue(data);
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTokens: (state, action) => {
            const { accessToken, refreshToken } = action.payload;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        },
        logout: (state) => {
            state.authLoading = false;
            state.isAuthenticated = false;
            state.accessToken = '';
            state.refreshToken = '';
            state.data = {};
        }
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.authLoading = true;
            state.initialAuthLoading = true;
        },
        [login.fulfilled]: (state, action) => {
            state.authLoading = false;
            state.isAuthenticated = true;
            state.accessToken = action.payload.token;
        },
        [login.rejected]: (state) => {
            state.authLoading = false;
            state.isAuthenticated = false;
            state.accessToken = '';
            state.refreshToken = '';
        },
        [getUserInfo.pending]: (state) => {
            state.authLoading = true;
        },
        [getUserInfo.fulfilled]: (state, action) => {
            state.authLoading = false;
            state.initialAuthLoading = false;
            state.isAuthenticated = true;
            state.data = action.payload;
        },
        [getUserInfo.rejected]: (state) => {
            state.authLoading = false;
            state.initialAuthLoading = false;
            state.isAuthenticated = false;
            state.data = {};
        },
        [getBuildInfo.pending]: (state) => {
            state.buildInfoLoading = true;
        },
        [getBuildInfo.rejected]: (state) => {
            state.buildInfoLoading = false;
            state.buildInfo = {};
        },
        [getBuildInfo.fulfilled]: (state, action) => {
            const { onpremise, site } = action.payload;
            state.buildInfoLoading = false;
            state.buildInfo = { onpremise, site };
        }
    }
});

export const { setTokens, logout } = authSlice.actions;

// selectors
export const selectAuthLoading = (state) => state.auth.authLoading;
export const selectInitialAuthLoading = (state) => state.auth.initialAuthLoading;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

export const selectUserId = (state) => state.auth.data?.user_id;
export const selectUserName = (state) => state.auth.data?.name;
export const selectUserEmail = (state) => state.auth.data?.email;
export const selectUserRole = (state) => state.auth.data.role;
export const selectIsNewUser = (state) => state.auth.data.newUser;

export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectIsRehydrated = (state) => state.auth._persist.rehydrated;

export const selectBuildOnPremise = (state) => state?.auth?.buildInfo?.onpremise;
export const selectBuildSite = (state) => state?.auth?.buildInfo?.site;
export const selectBuildInfoLoading = (state) => state?.auth?.buildInfoLoading;

export const selectUserMenus = (state) => state?.auth?.data?.menus ?? [];
export const selectUserNotification = (state) => state?.auth?.data?.notification ?? [];
export const selectUserSiteId = (state) => state.auth.data?.site;

export const selectHelpTexts = (state) => state?.auth?.data?.help ?? {};

export const selectAuthorizedModules = (state) => {
    const modules = [''];
    const menus = selectUserMenus(state);
    menus.forEach((menu) => {
        const hasSubmenus = menu.submenus && menu.submenus.length;
        if (hasSubmenus) {
            const submenus = menu.submenus;
            submenus.forEach((submenu) => {
                modules.push(submenu.path.replaceAll('/', ''));
            });
        } else {
            modules.push(menu.path.replaceAll('/', ''));
        }
    });
    modules.push("change-password");
    modules.push("registration");
    modules.push("help");
    return modules;
};

export const selectAuthorizedModulesMap = (state) => {
    const modules = {};
    const menus = selectUserMenus(state);
    menus.forEach((menu) => {
        const hasSubmenus = menu.submenus && menu.submenus.length;
        if (hasSubmenus) {
            const submenus = menu.submenus;
            submenus.forEach((submenu) => {
                modules[submenu.path.replaceAll('/', '')] = submenu.id;
            });
        } else {
            modules[menu.path.replaceAll('/', '')] = menu.id;
        }
    });
    return modules;
};

export default authSlice.reducer;
