import React, { useCallback, useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { cities } from '../../data/helpdata';
import { LoadingButton } from '@mui/lab';
import SwitchField from '../../libs/formik_fields/SwitchField';
import appUrls from '../../appUrls';

const emptyTyreBrand = {
    name: '',
    description: '',
    status: true
};

const tyreBrandSchema = yup.object().shape({
    name: yup.string().required('Please enter name of tire brand.'),
    address: yup.string('Please enter valid description.'),
    status: yup.mixed().required('Status is required.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const TyreBrandFormContent = (formik) => {
    const {
        isSubmitting
    } = formik;

    const navigate = useNavigate();

    const handleBack = useCallback(() => {
        navigate(appUrls.TYRE_BRAND);
    }, [navigate]);

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField name="name" label="Name*" />
                <TextField name="description" label="Description" rows={4.1} multiline />
                {/* eslint-disable-next-line react/destructuring-assignment */}
                {formik.values.editMode && (
                    <SwitchField name="status" label="Status" />
                )}

            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                >
                    Save
                </LoadingButton>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        ml: 1
                    }}
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Grid>
        </Grid>
    );
};

function TyreBrandForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyTyreBrand}
            onSubmit={handleSubmit}
            validationSchema={tyreBrandSchema}
            FormContent={TyreBrandFormContent}
        />
    );
}

export default TyreBrandForm;
