import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, Pagination, TextField, Typography } from '@mui/material';
import useModalState from 'hooks/useModalState';
import { DataGrid } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import ListProvider from '../ui/ListProvider';

import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import { makeStyles } from '@mui/styles';
import { getCommercialCustomerPurchaseListRequest } from '../../services/commercial_customer';
import { fetchTyreInventoryAnPriceChangeHistoryRequest } from 'services/tyre';
import { useSelector } from 'react-redux';
import { selectIsServiceActive } from 'store/slices/serviceSlice';
import appServices from 'appServices';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function TyreInventoryAndPriceChangeHistory({ buttonText = 'Change History', stock }) {
    const { isOpen, handleClose, handleOpen } = useModalState(false);
    const classes = useStyles();
    const isTyreStockWithBillingServiceActive = useSelector(selectIsServiceActive(appServices.TYRE_STOCK_MANAGEMENT_BILLING));



    const [initialData, setInitialData] = useState({
        search: {},
        sortParams: {
            column: 'cdate',
            order: 'asc'
        }
    });

    const [listMutation, setListMutation] = useState({});

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.TYRE});


    const columns = isTyreStockWithBillingServiceActive  ? [ 
        {
            field: 'tyre',
            headerName: 'Tyre',
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.tyre?.name}
                </Box>
            )
        },
        {
            field: 'tyreSize',
            headerName: 'Tyre Size',
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.size?.size}
                </Box>
            )
        },
        {
            field: 'stock',
            headerName: 'Stock',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'changeDate',
            headerName: 'Change Date',
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.changeDate}
                </Box>
            )
        },
        
        {
            field: 'comment',
            headerName: 'Comment',
            flex: 6,
            minWidth: 600
        }
        
    ] : [
        
        {
            field: 'tyre',
            headerName: 'Tyre',
            flex: 3,
            minWidth: 200,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.tyre?.name}
                </Box>
            )
        },
        {
            field: 'tyreSize',
            headerName: 'Tyre Size',
            flex: 3,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.size?.size}
                </Box>
            )
        },
        {
            field: 'stock',
            headerName: 'Stock',
            flex: 3,
            minWidth: 100
        },
       
        {
            field: 'changeDate',
            headerName: 'Change Date',
            flex: 3,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.changeDate}
                </Box>
            )
        },
        
        {
            field: 'comment',
            headerName: 'Comment',
            flex: 6,
            minWidth: 600,
            renderCell: ({ row }) => (
                <div dangerouslySetInnerHTML={{ __html: row?.comment }} />
            )
        }
        
    ];

    const fetchTyreInventoryAnPriceChangeHistory = async (data) => {
        const payload = createPayload({
            tyre : stock.tyre,
            size: stock.size
        });
        const response = await fetchTyreInventoryAnPriceChangeHistoryRequest(payload);
        console.log('-> response', response);
        const obj = {
            results: response,
            page: 1,
            totalPages: 1,
            totalResults: response.length,
            error: response.error
        };
        console.log('-> obj', obj);
        return obj;
    };

    return (
        <>
            <Button onClick={handleOpen} variant="outlined" color="primary">
                {buttonText}
            </Button>
            <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h3">Tyre Price and Inventory Change History</Typography>
                        <Button onClick={handleClose}>Close</Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        
                        {isOpen && (
                            <Grid item xs={12}>
                                <ListProvider
                                    fetcher={fetchTyreInventoryAnPriceChangeHistory}
                                    initialData={initialData}
                                    listMutation={listMutation}
                                >
                                    {(listProps) => (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box
                                                    p={1}
                                                    style={{
                                                        // height: 500,
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Box className={classes.progressBarContainer}>
                                                        {listProps.loading && <LinearProgress />}
                                                    </Box>
                                                    {listProps.listItems && !listProps.loading && (
                                                        <>
                                                            <DataGrid
                                                                rows={listProps.listItems}
                                                                columns={columns}
                                                                pagination={false}
                                                                paginationMode="server"
                                                                pageSize={listProps.limit}
                                                                page={listProps.page}
                                                                row
                                                                autoHeight
                                                                disableColumnMenu
                                                            />
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    mt: 2,
                                                                    mb: 0,
                                                                    pb: 0
                                                                }}
                                                            >
                                                                <Pagination
                                                                    color="primary"
                                                                    count={listProps.totalPages}
                                                                    page={listProps.page}
                                                                    onChange={(e, value) => {
                                                                        console.log('-> value', value);

                                                                        listProps.setPage(value);
                                                                    }}
                                                                />
                                                            </Box>
                                                        </>
                                                    )}
                                                    {listProps.initialLoading && (
                                                        <Grid container spacing={1}>
                                                            {[...Array(12)].map((_, i) => (
                                                                <Grid key={i} item xs={12}>
                                                                    <Skeleton variant="rect" height={40} />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </ListProvider>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default TyreInventoryAndPriceChangeHistory;
