import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

// eslint-disable-next-line import/prefer-default-export



export const saveTyreRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyre/save`, payload),
        successCode: 200
    });

export const getTyreListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyre/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getTyreDetailsRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyre/get`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getActiveTyreListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyre/active/list`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getActiveTyreListByBrandRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyre/active/list/bybrand`,payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });
    export const fetchTyreInventoryAnPriceChangeHistoryRequest = (payload) =>
        callApi({
            requestFunction: (axios) => axios.post(`${API_BASE_URL}/tyre/stock/history`,payload),
            successCode: 200,
            showToastOnSuccess: false,
            showToastOnError: true
        });