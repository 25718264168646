import React, { useState } from 'react';
import Breadcrumb from 'components/ui/Breadcrumb';
import { Box, Button, Card, CardContent, Grid, LinearProgress, Pagination, TextField, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import { DataGrid } from '@mui/x-data-grid';
import appUrls from 'appUrls';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ListProvider from 'components/ui/ListProvider';
import EditButton from 'components/ui/buttons/EditButton';
import DeleteButton from 'components/ui/buttons/DeleteButton';
import {
    deleteIncentiveEntryRequest,
    fetchLateFollowupCountRequest,
    getActiveUsersListForIncentiveEntry,
    getIncentiveEntryExcelRequest,
    getIncentiveEntryListRequest,
    getIncentiveEntryTotalRequest
} from 'services/incentive_entry';
import { LocalizationProvider } from '@mui/lab';
import _ from 'lodash';
import moment from 'moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useFetchSelectOptions from 'hooks/useFetchSelectOptions';
import { getActiveUsersListForJobCardProcess } from 'services/job_card';
import SelectFilter from '../../components/ui/list-filters/SelectFilter';
import { downloadFileFromResponse, getFirstDayOfCurrentMonth, getLastDayOfCurrentMonth } from '../../utils';
import ExcelButton from '../../components/ui/buttons/ExcelButton';
import DateFilter from '../../components/ui/list-filters/DateFilter';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function IncentiveEntryList(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({
        search: {
            title: '',
            salesMan: [],
            from: getFirstDayOfCurrentMonth(),
            to: getLastDayOfCurrentMonth()
        },
        sortParams: {
            column: 'cdate',
            order: 'asc'
        }
    });

    const [listMutation, setListMutation] = useState({});

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.INCENTIVE_ENTRY });

    const [total, setTotal] = useState('0.0');

    const [lateFollowup, setLateFollowup] = useState({
        orderLateFollowup: 0,
        quotationLateFollowup: 0
    });

    const { options: activeOperatorsOptions, loading: activeOperatorsOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = createPayload({});
            return getActiveUsersListForIncentiveEntry(payload);
        }
    });

    const columns = [
        {
            field: 'salesman',
            headerName: 'Salesman',
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.salesPerson}
                </Box>
            )
        },
        {
            field: 'segment',
            headerName: 'Segment',
            flex: 1,
            minWidth: 100,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.segment?.segment}
                </Box>
            )
        },
        {
            field: 'brand',
            headerName: 'Brand',
            flex: 1,
            minWidth: 100,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.brand?.name}
                </Box>
            )
        },
        {
            field: 'tyre',
            headerName: 'Tyre',
            flex: 1,
            minWidth: 100,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.tyre?.name}
                </Box>
            )
        },
       
        {
            field: 'tyreSize',
            headerName: 'Tyre Size',
            flex: 1,
            minWidth: 100,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.tyreSize?.size}
                </Box>
            )
        },
        {
            field: 'billNumber',
            headerName: 'Bill Number',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'vehicleMake',
            headerName: 'Vehicle Make',
            flex: 4,
            minWidth: 100
        },

        {
            field: 'saleDate',
            headerName: 'Sale Date',
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.salesDate}
                </Box>
            )
        },
        {
            field: 'listPrice',
            headerName: 'List Price',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'salePrice',
            headerName: 'Sale Price',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'incentive',
            headerName: 'Incentive',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'timelyOffer',
            headerName: 'Timely Offer',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'id',
            flex: 3,
            headerName: 'Actions',
            cellClassName: 'text-center',
            minWidth: 140,
            renderCell: ({ row }) => (
                <Box>
                    {/* <EditButton
                        color="info"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => navigate(`${appUrls.INCENTIVE_ENTRY_EDIT}/${row.id}`)}
                    /> */}
                    <DeleteButton
                        // color="info"
                        size="small"
                        style={{ marginLeft: 16 }}
                        handleDelete={async () => {
                            const payload = createPayload({ id: row.id });
                            const result = await deleteIncentiveEntryRequest(payload);
                            if (result.error === false) {
                                setListMutation({});
                            }
                        }}
                        entityName="Incentive Entry"
                    />
                </Box>
            )
        }
    ];

    const handleExcelDownload = async (data) => {
        const body = _.cloneDeep(data);
        console.log('-> body', body);
        body.search.from = moment(body?.search?.from).format('DD-MM-YYYY');
        body.search.to = moment(body?.search?.to).format('DD-MM-YYYY');
        const payload = createPayload(body);
        const result = await getIncentiveEntryExcelRequest(payload);
        if (!result.error) {
            downloadFileFromResponse(result, 'incentive_entries', 'xlsx', true);
        }
    };

    const fetchIncentiveEntriesList = async (data) => {
        const body = _.cloneDeep(data);
        console.log('-> body', body);
        body.search.from = moment(body?.search?.from).format('DD-MM-YYYY');
        body.search.to = moment(body?.search?.to).format('DD-MM-YYYY');
        const payload = createPayload(body);
        const filterPayload = createPayload(body.search);
        const totalResult = await getIncentiveEntryTotalRequest(filterPayload);
        const total = totalResult?.data?.data ?? '0.0';
        setTotal(total);
        const response = await fetchLateFollowupCountRequest(filterPayload);
        console.log(response.data.data);
        setLateFollowup(response.data.data);
        return getIncentiveEntryListRequest(payload);
    };

    return (
        <>
            <Breadcrumb title="Incentive Entries" />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <ListProvider fetcher={fetchIncentiveEntriesList} initialData={initialData} listMutation={listMutation}>
                                    {(listProps) => (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent="space-between" spacing={1}>
                                                    <Grid item xs={12} sm={7} md={6} lg={3}>
                                                        <SelectFilter
                                                            listProps={listProps}
                                                            name="salesMan"
                                                            labelKey="name"
                                                            valueKey="id"
                                                            label="Salesman"
                                                            options={activeOperatorsOptions}
                                                            isMulti
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={7} md={6} lg={2}>
                                                        <Box
                                                            width="100%"
                                                            sx={{
                                                                // px: 3,
                                                                borderRadius: 10,
                                                                background: 'white'
                                                            }}
                                                        >
                                                            <DateFilter id="from" name="from" listProps={listProps} label="From" />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={7} md={6} lg={2}>
                                                        <Box
                                                            width="100%"
                                                            sx={{
                                                                // px: 3,
                                                                borderRadius: 10,
                                                                background: 'white'
                                                            }}
                                                        >
                                                            <DateFilter id="to" name="to" listProps={listProps} label="To" />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={5} md={6} lg={2}>
                                                        <Box
                                                            display="flex"
                                                            sx={{
                                                                justifyContent: {
                                                                    xs: 'center'
                                                                },
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                height: '100%'
                                                            }}
                                                        >
                                                            <Typography variant="body1">
                                                                Total : {total}{' '}
                                                                <Typography color="red">(If all target achived.)</Typography>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={5} md={6} lg={3}>
                                                        <Box
                                                            display="flex"
                                                            justifyContent="flex-end"
                                                            sx={{
                                                                justifyContent: {
                                                                    xs: 'center',
                                                                    md: 'flex-end'
                                                                },
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <ExcelButton
                                                                onClick={() => {
                                                                    handleExcelDownload(listProps);
                                                                }}
                                                            />

                                                            <Button
                                                                className={classes.button}
                                                                variant="outlined"
                                                                color="primary"
                                                                sx={{
                                                                    mr: 1
                                                                }}
                                                                onClick={() => navigate(appUrls.INCENTIVE_ENTRY_ADD)}
                                                                disableElevation
                                                                startIcon={<AddIcon />}
                                                            >
                                                                Add Incentive Entry
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box
                                                    p={1}
                                                    style={{
                                                        // height: 500,
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Box className={classes.progressBarContainer}>
                                                        {listProps.loading && <LinearProgress />}
                                                    </Box>
                                                    {listProps.listItems && !listProps.loading && (
                                                        <>
                                                            <DataGrid
                                                                rows={listProps.listItems}
                                                                columns={columns}
                                                                pagination={false}
                                                                paginationMode="server"
                                                                pageSize={listProps.limit}
                                                                page={listProps.page}
                                                                row
                                                                autoHeight
                                                                disableColumnMenu
                                                            />
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    mt: 2,
                                                                    mb: 0,
                                                                    pb: 0
                                                                }}
                                                            >
                                                                <Pagination
                                                                    color="primary"
                                                                    count={listProps.totalPages}
                                                                    page={listProps.page}
                                                                    onChange={(e, value) => {
                                                                        console.log('-> value', value);

                                                                        listProps.setPage(value);
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h3">Late Followup </Typography>
                                                                <Typography>
                                                                    Order late followup : {lateFollowup?.orderLateFollowup}
                                                                </Typography>
                                                                <Typography>
                                                                    Quotation late followup : {lateFollowup?.quotationLateFollowup}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {listProps.initialLoading && (
                                                        <Grid container spacing={1}>
                                                            {[...Array(12)].map((_, i) => (
                                                                <Grid key={i} item xs={12}>
                                                                    <Skeleton variant="rect" height={40} />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </ListProvider>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </>
    );
}

export default IncentiveEntryList;
