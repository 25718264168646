import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// pages imports
const RegistrationPage = Loadable(lazy(() => import('pages/auth/registration')));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const RegistrationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
       
        {
            path: '/registration',
            element: <RegistrationPage />
        }
    ]
};

export default RegistrationRoutes;
