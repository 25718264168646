import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';
import moment from 'moment';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import ComplaintForm from '../../components/complaint/ComplaintForm';
import { saveQuotationRequest } from '../../services/quotation';
import QuotationForm from '../../components/quotation/QuotationForm';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddQuotationPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.QUOTATION });

    const navigate = useNavigate();

    const handleAddQuotation = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { segment, title, name, contactNo, subject, address, summary, assignTo, followupDate, remark, quotationDetail } = values;
        quotationDetail.forEach((qd) => {
            qd.warranty = qd.warranty.warranty
          });
        const data = {
            segment,
            title: title?.value ?? '',
            name,
            contactNo,
            subject,
            address,
            summary,
            remark,
            quotationDetail,
            followupDate: moment(followupDate).format('DD-MM-YYYY'),
            assignTo,
            status: {
                id: '1',
                status: 'Pending'
            }
        };
        const payload = payloadCreator(data);
        const response = await saveQuotationRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.QUOTATION_LIST);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Add Quotation" help_code='quotation_add'/>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <QuotationForm handleSubmit={handleAddQuotation} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddQuotationPage;
