import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import { FieldArray } from 'formik';
import StatusField from '../../libs/formik_fields/StatusField';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import useFetchSelectOptions from '../../hooks/useFetchSelectOptions';
import { getAllUserMenusListRequest } from '../../services/user';
import StatusSwitchField from '../../libs/formik_fields/StatusSwitchField';
import SwitchField from 'libs/formik_fields/SwitchField';

const emptyOperator = {
    userName: '',
    password: '',
    name: '',
    mobile: '',
    email: '',
    address: '',
    status: true,
};

const operatorSchema = yup.object().shape({
    name: yup.string().required('Please enter name of operator.'),
    mobile: yup.string().required('Please enter mobile number of operator.'),
    address: yup.string('Please enter valid address.'),
    status: yup.mixed().required('Status is required.'),
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const MachineOperatorFormContent = ({ errors, isSubmitting, values, setFieldValue }) => {
    console.log('-> errors', errors);

    const navigate = useNavigate();

    const [operatorPayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.OPERATOR_MANAGEMENT });





    const handleBack = useCallback(() => {
        navigate(appUrls.MACHINE_OPERATOR);
    }, [navigate]);

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            
            <Grid item xs={12} md={6}>
                <TextField name="name" label="Name*" />
                <TextField name="mobile" label="Mobile*" />
                
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField name="address" label="Address" rows={3.9} multiline />
                {values.editMode && <SwitchField name="status" label="Status" />}
            </Grid>
            
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={isSubmitting}
                    loadingPosition="center"
                >
                    Save
                </LoadingButton>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        ml: 1
                    }}
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Grid>
        </Grid>
    );
};

function MachineOperatorForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyOperator}
            onSubmit={handleSubmit}
            validationSchema={operatorSchema}
            FormContent={MachineOperatorFormContent}
        />
    );
}

export default MachineOperatorForm;
