import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'components/ui/Breadcrumb';
import { Box, Button, Card, CardContent, Grid, IconButton, LinearProgress, Pagination, TextField, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import { DataGrid } from '@mui/x-data-grid';
import appUrls from 'appUrls';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ListProvider from 'components/ui/ListProvider';
import EditButton from 'components/ui/buttons/EditButton';
import { getSMSTemplateListRequest } from '../../../services/sms_template';
import { getSMSIncentiveSetupListRequest, smsIncentiveSegmentOptionsList } from '../../../services/sms_incentive_setup';
import SelectFilter from '../../../components/ui/list-filters/SelectFilter';
import useFetchSelectOptions from '../../../hooks/useFetchSelectOptions';
import { getActiveTyreSizeListRequest } from '../../../services/tyre_size';
import { getActiveTyreListByBrandRequest, getActiveTyreListRequest } from '../../../services/tyre';
import { getActiveTyreBrandListRequest } from 'services/tire_brand';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    clientCardsContainer: {
        padding: theme.spacing(3)
        // marginTop: theme.spacing(1)
    },
    progressBarContainer: {
        height: 5
        // marginTop: 10,
        // marginBottom: 10
    }
}));

function IncentiveSetupList(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({
        search: { title: '', brand: null, tyre: null, segment: null },
        sortParams: {
            column: 'cdate',
            order: 'asc'
        }
    });

    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.INCENTIVE_SETUP });

    const columns = [
        {
            field: 'segment',
            headerName: 'Segment',
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.segment?.segment}
                </Box>
            )
        },
        {
            field: 'brand',
            headerName: 'Brand',
            flex: 1,
            minWidth: 100,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.brand?.name}
                </Box>
            )
        },
        {
            field: 'tyre',
            headerName: 'Tyre',
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.tyre?.name}
                </Box>
            )
        },
        {
            field: 'tyreSize',
            headerName: 'Tyre Size',
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    {row?.tyreSize?.size}
                </Box>
            )
        },
        {
            field: 'maxDiscount',
            headerName: '0 Incentive Discount',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'incentive',
            headerName: 'Incentive',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'timeltOffer',
            headerName: 'Timely Offer',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'sDate',
            headerName: 'Start Date',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'eDate',
            headerName: 'Start Date',
            flex: 4,
            minWidth: 100
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 100,
            renderCell: ({ row }) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{
                            color: row?.status?.status === 'ACTIVE' ? 'green' : 'red',
                            fontWeight: 'bold'
                        }}
                    >
                        {row?.status?.status}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'id',
            flex: 3,
            headerName: 'Actions',
            cellClassName: 'text-center',
            minWidth: 140,
            renderCell: ({ row }) => (
                <Box>
                    <EditButton
                        color="info"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => navigate(`${appUrls.INCENTIVE_SETUP_EDIT}/${row.id}`)}
                    />
                </Box>
            )
        }
    ];

    const fetchIncentiveSetupList = async (data) => {
        const payload = createPayload(data);
        return getSMSIncentiveSetupListRequest(payload);
    };

    const { options: activeTyreBrandOptions, loading: activeTyreBrandOptionsLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = createPayload({});
            return getActiveTyreBrandListRequest(payload);
        }
    });

    const [activeTyreOptions,setActiveTyreOptions] = useState([])
    const [activeTyreOptionsLoading, setAtiveTyreOptionsLoading] = useState(false);

    const fetchTyreData = async (data) => {
        setActiveTyreOptions(null);
            setAtiveTyreOptionsLoading(true);
            const payload = createPayload({
                id: data?.id
            });
            const result = await getActiveTyreListByBrandRequest(payload);
            if (result.error === false) {
                setActiveTyreOptions(result);
            }
            setAtiveTyreOptionsLoading(false);
       
    };

    return (
        <>
            <Breadcrumb title="Incentive Setup" />
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ListProvider fetcher={fetchIncentiveSetupList} initialData={initialData}>
                                {(listProps) => (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="space-between" spacing={1}>
                                                <Grid item xs={12} md={3} xl={3}>
                                                    <SelectFilter
                                                        name="segment"
                                                        label="Segment"
                                                        options={smsIncentiveSegmentOptionsList}
                                                        labelKey="segment"
                                                        valueKey="id"
                                                        listProps={listProps}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3} xl={3}>
                                                    <SelectFilter
                                                        name="brand"
                                                        label="Brand"
                                                        options={activeTyreBrandOptions}
                                                        labelKey="name"
                                                        valueKey="id"
                                                        listProps={listProps}
                                                        dependentSelect
                                                        dependentSelectRefresh={fetchTyreData}
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3} xl={3}>
                                                    <SelectFilter
                                                        name="tyre"
                                                        label="Tyre"
                                                        options={activeTyreOptions}
                                                        labelKey="name"
                                                        valueKey="id"
                                                        listProps={listProps}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="flex-end"
                                                        sx={{
                                                            justifyContent: {
                                                                xs: 'center',
                                                                md: 'flex-end'
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            className={classes.button}
                                                            variant="outlined"
                                                            color="primary"
                                                            sx={{
                                                                mr: 1
                                                            }}
                                                            onClick={() => navigate(appUrls.INCENTIVE_SETUP_ADD)}
                                                            disableElevation
                                                            startIcon={<AddIcon />}
                                                        >
                                                            Add Incentive
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                p={1}
                                                style={{
                                                    // height: 500,
                                                    width: '100%'
                                                }}
                                            >
                                                <Box className={classes.progressBarContainer}>
                                                    {listProps.loading && <LinearProgress />}
                                                </Box>
                                                {listProps.listItems && !listProps.loading && (
                                                    <>
                                                        <DataGrid
                                                            rows={listProps.listItems}
                                                            columns={columns}
                                                            pagination={false}
                                                            paginationMode="server"
                                                            pageSize={listProps.limit}
                                                            page={listProps.page}
                                                            row
                                                            autoHeight
                                                            disableColumnMenu
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                mt: 2,
                                                                mb: 0,
                                                                pb: 0
                                                            }}
                                                        >
                                                            <Pagination
                                                                color="primary"
                                                                count={listProps.totalPages}
                                                                page={listProps.page}
                                                                onChange={(e, value) => {
                                                                    console.log('-> value', value);

                                                                    listProps.setPage(value);
                                                                }}
                                                            />
                                                        </Box>
                                                    </>
                                                )}
                                                {listProps.initialLoading && (
                                                    <Grid container spacing={1}>
                                                        {[...Array(12)].map((_, i) => (
                                                            <Grid key={i} item xs={12}>
                                                                <Skeleton variant="rect" height={40} />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </ListProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default IncentiveSetupList;
