import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { gridSpacing } from '../../store/constant';
import { Box, Card, Divider, Drawer, FormControl, Grid, InputLabel, NativeSelect, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Help, HelpOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectHelpTexts } from 'store/slices/authSlice';
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
function HelpDrawer({ title, help_code='' }) {
    
  const helpText = JSON.parse(useSelector(selectHelpTexts));
  console.log(helpText)
  console.log("help_code>"+help_code+"<")
    const theme = useTheme();
    const [language, setLanguage] = useState('en');
    const [helpContent, setHelpContent] = useState(help_code === '' ? '' :helpText[help_code][language]);
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
      setLanguage('en');
      setHelpContent(helpText[help_code]['en'])
    };

    const languageChange = async (event) => {
      console.log(event.target.value)
      //setLanguage(event.target.value);
      setHelpContent(helpText[help_code][event.target.value])
    };

    return (
        <>
            <Box sx={{ p: 0, m:0 }}>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                    
                  {help_code !== '' &&  <Grid item >
                    
                        <Tooltip title={"Click here for help."}><HelpOutline onClick={toggleDrawer(true)} sx={{cursor:'pointer'}}/></Tooltip>                      </Grid> }
                </Grid>
            </Box>
            <Drawer open={open} onClose={toggleDrawer(false)} anchor='right' sx={{zIndex:"999999999999"}} PaperProps={{
            sx: { width: "50%" },
          }}>
            <React.Fragment>
              <Grid container spacing={1} sx={{padding:2}}>
              <Grid item xs={12}>
                <Typography variant='h2'>Help</Typography>
                </Grid>
              <Grid item xs={12}>
                <Divider/>
                </Grid>
                
                <Grid item xs={6}>
                    <Typography variant='h3' sx={{marginTop:1}}>{title}</Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'right'}}>
                <FormControl >
              
              <NativeSelect
                defaultValue={'en'}
                onChange={(e)=>languageChange(e)}
                inputProps={{
                  name: 'language',
                  id: 'uncontrolled-native',
                }}
              >
                <option value={'en'}>English</option>
                <option value={'hi'}>Hindi</option>
              </NativeSelect>
            </FormControl>
                </Grid>
                <Grid item xs={12}>
                <Divider/>
                </Grid>
                <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{__html: helpContent}}/>
                </Grid>
              </Grid>
            
           
          </React.Fragment>
            </Drawer></>
    );
}

export default HelpDrawer;
