import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import { saveTyreSizeRequest } from 'services/tyre_size';
import TyreSizeForm from 'components/tyre-size/TyreSizeForm';
import OperatorForm from '../../../components/operator/OperatorForm';
import { saveUserRequest } from '../../../services/user';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddOperatorPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [operatorPayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.OPERATOR_MANAGEMENT });

    const navigate = useNavigate();

    // eslint-disable-next-line consistent-return
    const handleAddOperator = async (values, { resetForm, setSubmitting, setErrors }) => {
        setSubmitting(true);
        const { userName, password, name, email, mobile, address, status, menus = [] } = values;
      //  if (!password) {
      //      return setErrors({ password: 'Password is required' });
      //  }
        const formattedMenus = [];
        menus.forEach((menu) => {
            if (menu && menu.hasAccess && menu.id) {
                formattedMenus.push({ id: menu.id });
                if (menu.submenus && menu.submenus.length) {
                    menu.submenus.forEach((submenu) => {
                        if (submenu && submenu.hasAccess && submenu.id) {
                            formattedMenus.push({ id: submenu.id });
                        }
                    });
                }
            }

        });
        const data = {
            userName,
            password,
            name,
            email,
            mobile,
            address,
            status: {
                id: status ? '1' : '2'
            },
            menus: formattedMenus
        };
        const payload = operatorPayloadCreator(data);
        console.log('-> payload', payload);
        const response = await saveUserRequest(payload);
        console.log('-> response', response);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.OPERATOR_MANAGEMENT);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Add System User" help_code='system_user_add'/>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <OperatorForm handleSubmit={handleAddOperator} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddOperatorPage;
