import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CardActions,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
// assets
import { selectUserNotification, selectUserRole } from 'store/slices/authSlice';
import { useSelector } from 'react-redux';
import { SupportAgent } from '@mui/icons-material';
import Transitions from 'ui-component/extended/Transitions';
import MainCard from 'ui-component/cards/MainCard';
import CallbackRequestModal from './CallbackRequestModal';

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const SupportSection = () => {
     const userRole = useSelector(selectUserRole);
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const notifications = useSelector(selectUserNotification);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [openModal, setOpenModal] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleModalToggle = () => {
        setOpen(false);
        setOpenModal(true);
    };

    const onModalClose = () => {
        setOpenModal(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => {
        if (event?.target.value) setValue(event?.target.value);
    };

    return (
        <>
            <Box
                sx={{
                    ml: 1,
                    mr: 1,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <SupportAgent stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                {userRole == 'SITE_ADMIN'  ?<Grid container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                            <Grid container  justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="h3">Feel free to connect</Typography>
                                                        
                                                    </Stack>
                                                </Grid>
                                               
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} spacing={2}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                <Grid container  justifyContent="space-between" sx={{ pt: 2,pb: 2, px: 2 }}>
                                                <Grid item >
                                                    <Typography variant='h4' sx={{ mb: 1 }}>Write to us</Typography>
                                                    <Typography variant='body'>support@tyreworkshop.com</Typography>
                                                </Grid>
                                                
                                               
                                               </Grid>
                                               <Divider/>
                                               <Grid container  justifyContent="space-between" sx={{ pt: 2,pb: 2, px: 2 }}>
                                               <Grid item spacing={2}>
                                                    <Typography variant='h4' sx={{ mb: 1 }}>Whatsapp us</Typography>
                                                    <Typography variant='body'>+91 8218033340</Typography>
                                                </Grid>
                                                
                                              
                                               </Grid>
                                               <Divider/>
                                              <Grid container  justifyContent="space-between" sx={{ pt: 2,pb: 2, px: 2 }}>
                                               <Grid item spacing={2}>
                                                    <Typography variant='h4' sx={{ mb: 1 }}>Request for call back</Typography>
                                                    <Button variant="contained" disableElevation onClick={handleModalToggle}>
                                                             Callback Request
                                                        </Button>
                                                </Grid>
                                                
                                                </Grid>
                                            </PerfectScrollbar>
                                            <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button size="small" disableElevation>
                                            { "Our team will connect you ASAP" }
                                        </Button>
                                    </CardActions>
                                        </Grid>
                                    </Grid>:""}
                                    <Divider />
                                    <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button size="small" disableElevation>
                                            {userRole == 'OPERATOR' ? "Support is only available for account owner." : ""}
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <CallbackRequestModal isOpen={openModal} onClose={onModalClose}/>
        </>
    );
};

export default SupportSection;
