import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { useSelector } from 'react-redux';

// assets
import { IconMenu2 } from '@tabler/icons';
import MyReminder from '../MyReminder';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getMyReminders } from 'services/dashboard';
import appModules from 'appModules';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import { selectUserName } from 'store/slices/authSlice';
import appUrls from 'appUrls';
import { Help, HelpCenter, Support, SupportAgent } from '@mui/icons-material';
import SupportSection from './SupportSection';
import HelpSection from './HelpSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const youHave = ' Pending ';
    const orderText = ' order followup';
    const quotationText = ' quotation followup';
    const comptaintText = ' complaint followup';
    const userName = useSelector(selectUserName);
    const theme = useTheme();
    const [myreminders, setMyreminders] = useState();
    const [createPayload] = useSiteModulePayloadCreator({ moduleName: appModules.DASHBOARD });

    const fetchMyReminderData = async () => {
        const payload = createPayload({});
        const result = await getMyReminders(payload);
        if (result.error === false) {
            const { error, ...data } = result;
            setMyreminders({
                ...data
            });
        }
    };

    useEffect(() => {
        fetchMyReminderData();
    }, []);

    // const navigate = useNavigate();

    // const navigateToComplaint = () => {
    //     navigate('/complaint');
    // };
    // const navigateToOrder = () => {
    //     navigate('/order');
    // };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    },
                    alignItems: 'center'
                }}
            >
                <Box component="div" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
             <Box sx={{ flexGrow: 1 }} />
            
            {/* {myreminders?.myOrder > 0 ? (
                <Box component="div" sx={{ display: { md: 'block' }, flexGrow: 1 }}>
                    <MyReminder key={1} openurl={appUrls.ORDER} data={youHave + myreminders?.myOrder + orderText} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1 }} />
            )}
            {myreminders?.myQuotation > 0 ? (
                <Box component="div" sx={{ display: { md: 'block' }, flexGrow: 1 }}>
                    <MyReminder key={1} openurl={appUrls.QUOTATION_LIST} data={youHave + myreminders?.myQuotation + quotationText} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1 }} />
            )}
            {myreminders?.myComplaint > 0 ? (
                <Box component="div" sx={{ display: { md: 'block' }, flexGrow: 1 }}>
                    <MyReminder key={2} openurl={appUrls.COMPLAINT} data={youHave + myreminders?.myComplaint + comptaintText} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1 }} />
            )} */}
            {/* <SearchSection/> */}
            <Box sx={{ flexGrow: 1 }} /> 
            <Box sx={{ flexGrow: 1 }} /> 
            <HelpSection />
            <SupportSection />
             <NotificationSection />
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
