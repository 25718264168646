import { API_BASE_URL } from './index';
import { callApi } from 'utils/callApi';

export const searchCustomerByVehicleNo = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/customer/search`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const searchCustomerByContactNo = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/customer/searchbymobile`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const customerDetailsByVehicleNo = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/customer/load`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const saveJobCardRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/save`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });
    export const saveTyreSaleJobCardRequest = (payload) =>
        callApi({
            requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/tyresale/save`, payload),
            successCode: 200,
            showToastOnSuccess: true,
            showToastOnError: true
        });
export const openJobCardListRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getJobCardDetailsByIdRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/get`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getJobCardDetailsByIdForProcessRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/get-process`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const jobCardWheelTypes = [
    { id: '1', value: 'Normal', code: 'nornal_wheel' },
    { id: '2', value: 'Alloy', code: 'alloy_wheel' }
];

// export const jobCardWheelBalancingTypes = [
//     { id: '0', value: 'Normal', code: 'wheel_alignment' },
//     { id: '1', value: 'Road Force', code: 'road_force_balancing' }
// ];

export const discountOptionsList = [
    { id: 'Discount(%)', value: 'Discount(%)' },
    { id: 'Discount', value: 'Discount' },
];

export const jobCardN2Types = [
    { id: '1', value: 'FillUp', code: 'fill_up' },
    { id: '2', value: 'TopUp', code: 'top_up' }
];

export const jobCardTyreRepairTypes = [
    { id: '1', value: 'String Repair', code: 'string_repair' },
    { id: '2', value: 'Mushroom Repair', code: 'mashroom_repair' }
];

export const jobCardStatus = [
    { id: '2', value: 'Close With Bill' },
    { id: '3', value: 'Close Without Bill' }
];

export const jobCardCloseWithoutBillReason = [
    { id: '1', value: 'WHEEL BEARING(S) LH/RH' },
    { id: '2', value: 'BALL JOINTS-STEERING/SUSPENSION' },
    { id: '3', value: 'TIE ROD END(S) LH/RH' },
    { id: '4', value: 'SUSPENSION BUSHES/STEERING RUBBER BOTS' },
    { id: '5', value: 'IDLER ARM/DROP ARM BUSH(S)' },
    { id: '6', value: 'STEERING WHEEL FREE PLAY' },
    { id: '7', value: 'CHASSIS CORROSION/DISTORTION' },
    { id: '8', value: 'UNVEN HEIGHT' },
    { id: '9', value: 'SHOCKER LEAKAGE LH/RH' },
    { id: '10', value: 'SHOCKER KIT LH/RH' },
    { id: '11', value: 'OTHER' }
];

export const jobCardReasonForNotBalancingOptions = [
    { id: '1', value: 'Run flat' },
    { id: '2', value: 'Worn off' },
    { id: '3', value: 'Bulgde' },
    { id: '4', value: 'Diff Size' },
    { id: '5', value: 'Shoulder Wear' },
    { id: '6', value: 'Rim Unbalanced' },
    { id: '7', value: 'Rim not Alloy' },
    { id: '8', value: 'Order for 2/4 tyre only' },
    { id: '9', value: 'Uneven Wear' },
    { id: '10', value: 'New' },
    { id: '11', value: 'Retreaded' },
    { id: '12', value: 'Repaired' },
    { id: '13', value: 'Already Balanced' },
    { id: '14', value: 'has through cut' },
    { id: '15', value: 'Different Brand' },
    { id: '16', value: 'One tyre OK' },
    { id: '17', value: 'Stepney not available' },
    { id: '19', value: 'Dry' },
    { id: '20', value: 'Mud or Snow' },
    { id: '21', value: 'Rim Different' },
    { id: '22', value: 'Tyre Over Weight' },
    { id: '18', value: 'Other' }
];

export const getVehicleModelRateInfoById = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/vehicle/ratemodel`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const getActiveUsersListForJobCardProcess = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/user/operator/list`, payload),
        successCode: 200,
        showToastOnSuccess: false,
        showToastOnError: true
    });

export const processJobCardWithoutBillRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/closewiithoutbill`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });

export const processJobCardWithBillRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/closewithbill`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });

export const downloadJobCardPdfRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/downloadwithold`, payload, { responseType: 'blob' }),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true,
        checkResponseData: false
    });

export const downloadJobCardPdfNewRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/download`, payload, { responseType: 'blob' }),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true,
        checkResponseData: false
    });

export const deleteJobCardRequest = (payload) =>
    callApi({
        requestFunction: (axios) => axios.post(`${API_BASE_URL}/jobcard/delete`, payload),
        successCode: 200,
        showToastOnSuccess: true,
        showToastOnError: true
    });
