import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import TextField from 'libs/formik_fields/TextField';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import moment from 'moment';
import FormikFormWrapper from 'libs/formik_fields/FormikFormWrapper';
import { LoadingButton } from '@mui/lab';
import appUrls from '../../appUrls';
import SelectField from '../../libs/formik_fields/SelectField';
import StatusSwitchField from '../../libs/formik_fields/StatusSwitchField';
import useFetchSelectOptions from '../../hooks/useFetchSelectOptions';
import useSiteModulePayloadCreator from '../../hooks/useSiteModuleProvider';
import appModules from '../../appModules';
import { getActiveTyreListByBrandRequest, getActiveTyreListRequest } from '../../services/tyre';
import { getActiveTyreSizeListRequest } from '../../services/tyre_size';
import { getDetailsByJobcardRequest, incentiveEntrySegmentOptionsList } from '../../services/incentive_entry';
import DateTimeInputField from 'libs/formik_fields/DateInputField';
import { getActiveTyreBrandListRequest } from 'services/tire_brand';

const emptyIncentiveEntry = {
    jobcardnumber: '',
    segment: null,
    brand: null,
    tyre: null,
    tyreSize: null,
    customer: {
        name: '',
        email: '',
        address: '',
        mobile: ''
    },
    listPrice: 0,
    salePrice: 0,
    quantity: 0,
    vehicleMake: '',
    salesDate: new Date()
};

const incentiveEntrySchema = yup.object().shape({
    segment: yup.mixed().required('Please select segment.'),
    brand: yup.mixed().required('Please select tyre brand.'),
    tyre: yup.mixed().required('Please select tyre.'),
    tyreSize: yup.mixed().required('Please select tyre size.'),
    customer: yup.object().shape({
        name: yup.string().required('Customer name is required.'),
        // email: yup.string().required('Customer email is required.'),
        // address: yup.string().required('Customer address is required.'),
        mobile: yup.string().required('Customer mobile is required.')
    }),
    listPrice: yup.mixed().required('Please enter list price.'),
    salePrice: yup.mixed().required('Please enter sale price.'),
    quantity: yup.mixed().required('Please enter quantity.'),
    vehicleMake: yup.mixed().required('Please enter vehicle make.')
});

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const IncentiveFormContent = (formik) => {
    const { isSubmitting, values, errors, setFieldValue } = formik;
    const { editMode } = values;
    // const { jobcardnumber, setJobcardnumber } = useState('sfdsfd');
    const navigate = useNavigate();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.INCENTIVE_ENTRY });

    const { options: activeTyresBrand, loading: activeTyresBrandLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = payloadCreator({});
            return getActiveTyreBrandListRequest(payload);
        }
    });

    const selectedTyreBrandId = useMemo(() => values?.brand?.id, [values?.brand?.id]);
    const [activeTyreOptions,setActiveTyreOptions] = useState([])
    const [activeTyreOptionsLoading, setAtiveTyreOptionsLoading] = useState(false);

    useEffect(() => {
        async function fetchTyres(brandId) {
            setAtiveTyreOptionsLoading(true);
            const payload = payloadCreator({
                id: brandId
            });
            const result = await getActiveTyreListByBrandRequest(payload);
            if (result.error === false) {
                setActiveTyreOptions(result);
                
                    setFieldValue('tyre', null);
                    setFieldValue('tyreSize', null);
                
                    
               
            }
            setAtiveTyreOptionsLoading(false);
        }

        if (selectedTyreBrandId) {
            fetchTyres(selectedTyreBrandId);
        }
    }, [selectedTyreBrandId]);

    const { options: segmentList, loading: segmentListLoading } = useFetchSelectOptions({
        fetcher: async () => {
            const payload = payloadCreator({});
            return incentiveEntrySegmentOptionsList(payload);
        }
    });

    const selectedTyreId = useMemo(() => values?.tyre?.id, [values?.tyre?.id]);
    const [activeTyreSize,setActiveTyreSize] = useState([])
    const [activeTyreSizeLoading, setActiveTyreSizeLoading] = useState(false);

    useEffect(() => {
        async function fetchTyres(selectedTyreId) {
            setActiveTyreSizeLoading(true);
            const payload = payloadCreator({
                reqData: selectedTyreId
            });
            const result = await getActiveTyreSizeListRequest(payload);
            if (result.error === false) {
                setActiveTyreSize(result);
                setFieldValue('tyreSize', null);
            }
            setActiveTyreSizeLoading(false);
        }

        if (selectedTyreId) {
            fetchTyres(selectedTyreId);
        }
    }, [selectedTyreId]);

    // const { options: activeTyreSize, loading: activeTyreSizeLoading } = useFetchSelectOptions({
    //     fetcher: async () => {
    //         const payload = payloadCreator({});
    //         return getActiveTyreSizeListRequest(payload);
    //     }
    // });

    const handleBack = useCallback(() => {
        navigate(appUrls.INCENTIVE_ENTRY_LIST);
    }, [navigate]);

    const classes = useStyles();
    const fetchDetailsByJobcard = async () => {
        const detailsReqPayload = payloadCreator({ id: values?.jobcardnumber });
        const data = await getDetailsByJobcardRequest(detailsReqPayload);
        console.log('-> data', data);
        if (data.error === false) {
            const detailsObj = data;
            setFieldValue('customer.name', data?.customer?.name);
            setFieldValue('customer.mobile', data?.customer?.mobile);
            setFieldValue('vehicleMake', data?.vehicleMake);
            setFieldValue('tyre', data?.tyre);
            setFieldValue('brand', data?.brand);
            setFieldValue('tyreSize', data?.tyreSize);
            setFieldValue('quantity', data?.quantity);
            setFieldValue('salesDate', moment(data?.salesDate, 'DD/MM/YYYY').toDate());

            console.log('-> data', data);
        }
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} px={1}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <Grid container direction="row" wrap="nowrap">
                            <Grid item xs={8}>
                                <TextField name="jobcardnumber" label="Job Card No" />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    sx={{
                                        ml: 1
                                    }}
                                    onClick={fetchDetailsByJobcard}
                                >
                                    Fill
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid itemx xs={12}>
                        <SelectField name="segment" placeholder="Segment" valueKey="id" labelKey="segment" options={segmentList} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <SelectField name="brand" placeholder="Brand" valueKey="id" labelKey="name" options={activeTyresBrand} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <SelectField name="tyre" placeholder="Tyre" valueKey="id" labelKey="name" options={activeTyreOptions} />
                    </Grid>
                    <Grid itemx xs={12}>
                        <SelectField name="tyreSize" placeholder="Tyre Size" valueKey="id" labelKey="size" options={activeTyreSize} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type="number" name="listPrice" label="List Price*" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type="number" name="salePrice" label="Sold Price*" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type="number" name="quantity" label="Quantity*" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="billNumber" label="Bill No*" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField name="customer.name" label="Customer Name*" />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField name="customer.email" label="Customer Email*" />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField name="customer.mobile" label="Customer Mobile*" />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField name="customer.address" label="Customer Address*" />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField name="vehicleMake" label="Vehicle Make*" />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimeInputField name="salesDate" label="Sales Date*" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} pt={2}>
                <Grid container>
                    <Grid itemx xs={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disableElevation
                            loading={isSubmitting}
                            loadingPosition="center"
                        >
                            Save
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                                ml: 1
                            }}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

function IncentiveEntryForm({ handleSubmit, initialValues }) {
    return (
        <FormikFormWrapper
            initialValues={initialValues ?? emptyIncentiveEntry}
            onSubmit={handleSubmit}
            validationSchema={incentiveEntrySchema}
            FormContent={IncentiveFormContent}
        />
    );
}

export default IncentiveEntryForm;
