import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, StyledEngineProvider } from '@mui/material';
import { Helmet } from 'react-helmet';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useMemo } from 'react';
import { getBuildInfo, selectBuildInfoLoading, selectBuildSite } from './store/slices/authSlice';
import { getSiteAssets } from './assetProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import appUrls from 'appUrls';

// ==============================|| APP ||============================== //

const App = () => {
    const buildInfoLoading = useSelector(selectBuildInfoLoading);
    const siteName = useSelector(selectBuildSite);
    const defaultTheme = useMemo(() => themes(siteName), [siteName]);

    const dispatch = useDispatch();
    const siteAssets = useMemo(() => getSiteAssets(siteName), [siteName]);
    
    const location = useLocation();
    console.log(location.pathname);

    useEffect(() => {
            dispatch(getBuildInfo());
    }, []);

 
    if (buildInfoLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                    flexDirection: 'column'
                }}
            >
                <img src="/loading.gif" width={50} alt="Loading..." />
            </Box>
        );
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={defaultTheme}>
                <Helmet>
                    <link rel="shortcut icon" href={siteAssets.favicon} type="image/x-icon" />
                    <link rel="icon" href={siteAssets.favicon} type="image/x-icon" />
                </Helmet>
                <ToastContainer />
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
