import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from 'components/ui/Breadcrumb';

import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import appUrls from 'appUrls';
import MachineOperatorForm from 'components/machineoperator/MachineOperatorForm';
import { saveMachineOperatorRequest } from 'services/machine_operator';

const useStyles = makeStyles((theme) => ({}));

// eslint-disable-next-line no-unused-vars
function AddMachineOperatorPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [operatorPayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.MACHINE_OPERATOR });

    const navigate = useNavigate();

    // eslint-disable-next-line consistent-return
    const handleAddOperator = async (values, { resetForm, setSubmitting, setErrors }) => {
        setSubmitting(true);
        const { name, mobile, address, status } = values;
      
        const data = {
            name,
            mobile,
            address,
            status: {
                id: status ? '1' : '2'
            }
        };
        const payload = operatorPayloadCreator(data);
        console.log('-> payload', payload);
        const response = await saveMachineOperatorRequest(payload);
        console.log('-> response', response);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.MACHINE_OPERATOR);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Add Machine Operator" help_code='machine_operator_add'/>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        <MachineOperatorForm handleSubmit={handleAddOperator} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AddMachineOperatorPage;
