import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import appUrls from '../appUrls';

import ChangePasswordPage from '../pages/auth/change-password';



import LogoutPage from '../pages/auth/logout';
import AddTyreBrandPage from '../pages/master/tyre_brand/add';
import EditTyreBrandPage from '../pages/master/tyre_brand/edit';
import AddTyreSizePage from '../pages/master/tyre_size/add';
import EditTyreSizePage from '../pages/master/tyre_size/edit';
import AddTyrePage from '../pages/master/tyre/add';
import EditTyre from '../pages/master/tyre/edit';
import EditVehicle from '../pages/master/vehicle/edit';
import AddVehiclePage from '../pages/master/vehicle/add';
import AddMachineOperatorPage from '../pages/master/machineoperator/add';
import EditMachineOperatorPage from '../pages/master/machineoperator/edit';

import AddOperatorPage from '../pages/administrator/operator_management/add';
import EditOperatorPage from '../pages/administrator/operator_management/edit';
import EditJobCardPage from '../pages/job_card/edit';
import ProcessJobCardPage from '../pages/job_card/process';
import ComplaintEditPage from '../pages/complaint/edit';
import ComplaintCommentsListPage from '../pages/complaint/comment-history';
import AddQuotationPage from '../pages/quotation/add';
import QuotationListPage from '../pages/quotation';
import QuotationEditPage from '../pages/quotation/edit';
import AddOrderPage from '../pages/order/add';
import OrderEditPage from '../pages/order/edit';
import AddSmsTemplatePage from '../pages/administrator/sms_template/add';
import EditSmsTemplatePage from '../pages/administrator/sms_template/edit';
import IncentiveSetupList from '../pages/administrator/incentive_setup';
import AddIncentiveSetupPage from '../pages/administrator/incentive_setup/add';
import EditIncentiveSetupPage from '../pages/administrator/incentive_setup/edit';
import IncentiveEntryList from '../pages/incentive-entry';
import AddIncentiveEntryPage from '../pages/incentive-entry/add';
import EditIncentiveEntryPage from '../pages/incentive-entry/edit';
import CommercialCustomersPage from '../pages/commercial-customers';
import SendSmsListPage from '../pages/administrator/send_sms';

// dashboard routing
const DashboardPage = Loadable(lazy(() => import('pages/default_dashboard')));
//const ChangePasswordPage = Loadable(lazy(() => import('pages/default_dashboard/changepassword')));
const HomePage = Loadable(lazy(() => import('pages/home')));

// administrator pages
const TaxSlabsPage = Loadable(lazy(() => import('pages/administrator/tax_slabs')));
const BillTemplatePage = Loadable(lazy(() => import('pages/administrator/bill_template')));
const ServiceSetupPage = Loadable(lazy(() => import('pages/administrator/service_setup')));
const IncentiveTargetPage = Loadable(lazy(() => import('pages/administrator/incentive_target')));
const OperatorManagementPage = Loadable(lazy(() => import('pages/administrator/operator_management')));
const SmsAndEmailSettingsPage = Loadable(lazy(() => import('pages/administrator/sms_and_email')));
const SmsTemplatePage = Loadable(lazy(() => import('pages/administrator/sms_template')));
const QuotationTemplate = Loadable(lazy(() => import('pages/administrator/quotation_template')));

// master pages
const TyreBrandPage = Loadable(lazy(() => import('pages/master/tyre_brand')));
const TyrePage = Loadable(lazy(() => import('pages/master/tyre')));
const TyreSizePage = Loadable(lazy(() => import('pages/master/tyre_size')));
const RateListPage = Loadable(lazy(() => import('pages/master/rate_list')));
const VehiclePage = Loadable(lazy(() => import('pages/master/vehicle')));
const MachineOperatorPage = Loadable(lazy(() => import('pages/master/machineoperator')));

const JobCardPage = Loadable(lazy(() => import('pages/job_card')));
const AddJobCardPage = Loadable(lazy(() => import('pages/job_card/add')));
const TyreSalePage = Loadable(lazy(() => import('pages/job_card/tyresale')));

const BillingPage = Loadable(lazy(() => import('pages/billing')));
const ComplaintPage = Loadable(lazy(() => import('pages/complaint')));
const ComplaintAddPage = Loadable(lazy(() => import('pages/complaint/add')));
const CustomersListPage = Loadable(lazy(() => import('pages/customers')));
const OrderPage = Loadable(lazy(() => import('pages/order')));

// Report pages
const ReportOnePage = Loadable(lazy(() => import('pages/reports/report_1')));
const ReportTwoPage = Loadable(lazy(() => import('pages/reports/report_2')));
const ReportThreePage = Loadable(lazy(() => import('pages/reports/report_3')));
const ReportFourPage = Loadable(lazy(() => import('pages/reports/report_4')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: appUrls.LOGOUT,
            element: <LogoutPage />
        },
        {
            path: appUrls.HOME,
            element: <HomePage />
        },
        {
            path: appUrls.DASHBOARD,
            element: <DashboardPage />
        },
        {
            path: appUrls.CHANGE_PASSWORD,
            element: <ChangePasswordPage />
        },
       
        {
            path: appUrls.ADMIN_DASHBOARD,
            element: <DashboardPage />
        },
        // Administrator routes
        {
            path: appUrls.TAX_SLAB,
            element: <TaxSlabsPage />
        },
        {
            path: appUrls.BILL_TEMPLATE,
            element: <BillTemplatePage />
        },
        {
            path: appUrls.SERVICE_SETUP,
            element: <ServiceSetupPage />
        },
        {
            path: appUrls.INCENTIVE_TARGET,
            element: <IncentiveTargetPage />
        },
        {
            path: appUrls.OPERATOR_MANAGEMENT,
            element: <OperatorManagementPage />
        },
        {
            path: appUrls.OPERATOR_MANAGEMENT_ADD,
            element: <AddOperatorPage />
        },
        {
            path: `${appUrls.OPERATOR_MANAGEMENT_EDIT}/:operatorId`,
            element: <EditOperatorPage />
        },
        {
            path: appUrls.SMS_AND_EMAIL_SETTINGS,
            element: <SmsAndEmailSettingsPage />
        },
        {
            path: appUrls.SMS_TEMPLATE_LIST,
            element: <SmsTemplatePage />
        },
        {
            path: appUrls.SMS_TEMPLATE_ADD,
            element: <AddSmsTemplatePage />
        },
        {
            path: `${appUrls.SMS_TEMPLATE_EDIT}/:smsTemplateId`,
            element: <EditSmsTemplatePage />
        },
        {
            path: appUrls.INCENTIVE_SETUP_LIST,
            element: <IncentiveSetupList />
        },
        {
            path: appUrls.INCENTIVE_SETUP_ADD,
            element: <AddIncentiveSetupPage />
        },
        {
            path: `${appUrls.INCENTIVE_SETUP_EDIT}/:incentiveSetupId`,
            element: <EditIncentiveSetupPage />
        },

        {
            path: appUrls.INCENTIVE_ENTRY_LIST,
            element: <IncentiveEntryList />
        },
        {
            path: appUrls.INCENTIVE_ENTRY_ADD,
            element: <AddIncentiveEntryPage />
        },
        {
            path: `${appUrls.INCENTIVE_ENTRY_EDIT}/:incentiveEntryId`,
            element: <EditIncentiveEntryPage />
        },

        {
            path: appUrls.QUOTATION_TEMPLATE,
            element: <QuotationTemplate />
        },
        // Master Page
        {
            path: appUrls.TYRE_BRAND_ADD,
            element: <AddTyreBrandPage />
        },
        {
            path: `${appUrls.TYRE_BRAND_EDIT}/:tyreBrandId`,
            element: <EditTyreBrandPage />
        },
        {
            path: appUrls.TYRE_BRAND,
            element: <TyreBrandPage />
        },
        {
            path: appUrls.TYRE,
            element: <TyrePage />
        },
        {
            path: appUrls.TYRE_ADD,
            element: <AddTyrePage />
        },
        {
            path: `${appUrls.TYRE_EDIT}/:tyreId`,
            element: <EditTyre />
        },
        {
            path: appUrls.TYRE_SIZE,
            element: <TyreSizePage />
        },
        {
            path: appUrls.TYRE_SIZE_ADD,
            element: <AddTyreSizePage />
        },
        {
            path: `${appUrls.TYRE_SIZE_EDIT}/:tyreSizeId`,
            element: <EditTyreSizePage />
        },
        {
            path: appUrls.RATE_LIST,
            element: <RateListPage />
        },
        {
            path: appUrls.VEHICLE,
            element: <VehiclePage />
        },
        {
            path: appUrls.VEHICLE_ADD,
            element: <AddVehiclePage />
        },
        {
            path: `${appUrls.VEHICLE_EDIT}/:vehicleId`,
            element: <EditVehicle />
        },
        {
            path: appUrls.MACHINE_OPERATOR,
            element: <MachineOperatorPage />
        },
        {
            path: appUrls.MACHINE_OPERATOR_ADD,
            element: <AddMachineOperatorPage />
        },
        {
            path: `${appUrls.MACHINE_OPERATOR_EDIT}/:operatorId`,
            element: <EditMachineOperatorPage />
        },
        // Master page end
        {
            path: appUrls.JOB_CARD,
            element: <JobCardPage />
        },
        {
            path: appUrls.JOB_CARD_ADD,
            element: <AddJobCardPage />
        },
        {
            path: appUrls.JOB_CARD_ADD_TYRE_SALE,
            element: <TyreSalePage />
        },
        {
            path: `${appUrls.JOB_CARD_EDIT}/:jobCardId`,
            element: <EditJobCardPage />
        },
        {
            path: `${appUrls.JOB_CARD_PROCESS}/:jobCardId`,
            element: <ProcessJobCardPage />
        },
        {
            path: appUrls.BILLING,
            element: <BillingPage />
        },
        {
            path: appUrls.COMPLAINT,
            element: <ComplaintPage />
        },
        {
            path: appUrls.COMPLAINT_ADD,
            element: <ComplaintAddPage />
        },
        {
            path: `${appUrls.COMPLAINT_EDIT}/:complaintId`,
            element: <ComplaintEditPage />
        },
        {
            path: `${appUrls.COMPLAINT_COMMENT_HISTORY}/:complaintId`,
            element: <ComplaintCommentsListPage />
        },
        {
            path: appUrls.QUOTATION_LIST,
            element: <QuotationListPage />
        },
        {
            path: appUrls.QUOTATION_ADD,
            element: <AddQuotationPage />
        },
        {
            path: `${appUrls.QUOTATION_EDIT}/:quotationId`,
            element: <QuotationEditPage />
        },
        {
            path: appUrls.CUSTOMERS,
            element: <CustomersListPage />
        },
        {
            path: appUrls.COMMERCIAL_CUSTOMERS,
            element: <CommercialCustomersPage />
        },
        {
            path: appUrls.SEND_SMS,
            element: <SendSmsListPage />
        },
        {
            path: appUrls.ORDER,
            element: <OrderPage />
        },
        {
            path: appUrls.ORDER_ADD,
            element: <AddOrderPage />
        },
        {
            path: `${appUrls.ORDER_EDIT}/:orderId`,
            element: <OrderEditPage />
        },
        // Reports Page
        {
            path: appUrls.REPORT_1,
            element: <ReportOnePage />
        },
        {
            path: appUrls.REPORT_2,
            element: <ReportTwoPage />
        },
        {
            path: appUrls.REPORT_3,
            element: <ReportThreePage />
        },
        {
            path: appUrls.REPORT_4,
            element: <ReportFourPage />
        }
    ]
};

export default MainRoutes;
