import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import appUrls from 'appUrls';
import Breadcrumb from 'components/ui/Breadcrumb';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import SmsTemplateForm from 'components/sms-template/SmsTemplateForm';
import { getSMSTemplateDetailsRequest, saveSMSTemplateRequest } from 'services/sms_template';
import { getSMSIncentiveSetupDetailsRequest, saveSMSIncentiveSetupRequest } from '../../../services/sms_incentive_setup';
import IncentiveSetupForm from '../../../components/insentive-setup/InsentiveSetupForm';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}));

// eslint-disable-next-line no-unused-vars
function EditIncentiveSetupPage(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [payloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.INCENTIVE_SETUP });

    const navigate = useNavigate();

    const { incentiveSetupId } = useParams();
    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            setDetailsLoading(true);
            const detailsReqPayload = payloadCreator({ id: incentiveSetupId });
            const data = await getSMSIncentiveSetupDetailsRequest(detailsReqPayload);
            console.log('-> data', data);
            if (data.error === false) {
                const detailsObj = data;
                detailsObj.status = detailsObj.status.id;
                detailsObj.timeltOffer = parseInt(detailsObj.timeltOffer, 10);
                detailsObj.sDate = detailsObj.sDate != null ? moment(detailsObj.sDate, 'DD/MM/YYYY').toDate() : null;
                detailsObj.eDate = detailsObj.eDate != null ? moment(detailsObj.eDate, 'DD/MM/YYYY').toDate() : null;
                detailsObj.editMode = true;
                setDetails(detailsObj);
            }
            setDetailsLoading(false);
        };
        fetchDetails();
    }, [incentiveSetupId]);

    const handleIncentiveSetupEdit = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { segment, brand,tyre, tyreSize, maxDiscount, incentive, status, timeltOffer, sDate, eDate } = values;
        const data = {
            id: incentiveSetupId,
            segment,
            brand,
            tyre,
            tyreSize,
            maxDiscount,
            incentive,
            timeltOffer,
            sDate,
            eDate,
            status: {
                id: status
            }
        };
        data.sDate = moment(data?.sDate).format('DD-MM-YYYY');
        data.eDate = moment(data?.eDate).format('DD-MM-YYYY');
        const payload = payloadCreator(data);
        const response = await saveSMSIncentiveSetupRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.INCENTIVE_SETUP_LIST);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Incentive Setup" />
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {!detailsLoading && details ? (
                                            <IncentiveSetupForm initialValues={details} handleSubmit={handleIncentiveSetupEdit} />
                                        ) : null}

                                        {detailsLoading && (
                                            <Grid container spacing={3}>
                                                {[...Array(8)].map((_, index) => (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <Skeleton variant="rect" height={40} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EditIncentiveSetupPage;
