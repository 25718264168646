import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appUrls from 'appUrls';
import Breadcrumb from 'components/ui/Breadcrumb';
import useSiteModulePayloadCreator from 'hooks/useSiteModuleProvider';
import appModules from 'appModules';
import { getTyreDetailsRequest, saveTyreRequest } from 'services/tyre';
import { pickProperties } from 'utils';
import TyreForm from 'components/tyre/TyreForm';

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    breadcrumbs: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}));

// eslint-disable-next-line no-unused-vars
function EditTyre(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();

    const [tyrePayloadCreator] = useSiteModulePayloadCreator({ moduleName: appModules.TYRE });

    const navigate = useNavigate();

    const { tyreId } = useParams();
    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            setDetailsLoading(true);
            const detailsReqPayload = tyrePayloadCreator({ id: tyreId });
            const data = await getTyreDetailsRequest(detailsReqPayload);
            if (data.error === false) {
                const detailsObj = data;
                detailsObj.status = detailsObj.status.id;
                detailsObj.stock = detailsObj.stock.map(item=>({
                    ...item,
                    status: item.status.id
                }));
                detailsObj.editMode = true;
                setDetails(detailsObj);
            }
            setDetailsLoading(false);
        };
        fetchDetails();
    }, [tyreId]);

    const handleTyreUpdate = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const { name, description, status, tyreBrand, stock } = values;
        const data = {
            id: tyreId,
            name,
            description,
            status: {
                id: status
            },
            tyreBrand: pickProperties(tyreBrand, ['id']),
            stock: stock.map((item) => ({
                id: item.id,
                size: pickProperties(item.size, ['id']),
                status: { id: item.status },
                stock: Number(item.stock),
                price: Number(item.price)
            }))
        };
        const payload = tyrePayloadCreator(data);
        const response = await saveTyreRequest(payload);
        if (response.error === false) {
            resetForm();
            navigate(appUrls.TYRE);
        }
        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb title="Edit Tyre" help_code='tyre_edit'/>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={11} xl={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Box p={1}>
                                        {!detailsLoading && details ? (
                                            <TyreForm initialValues={details} handleSubmit={handleTyreUpdate} />
                                        ) : null}

                                        {detailsLoading && (
                                            <Grid container spacing={3}>
                                                {[...Array(8)].map((_, index) => (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <Skeleton variant="rect" height={40} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EditTyre;
