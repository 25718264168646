import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { gridSpacing } from '../../store/constant';
import { Box, Card, Divider, Drawer, FormControl, Grid, InputLabel, NativeSelect, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Help, HelpOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectHelpTexts } from 'store/slices/authSlice';
import HelpDrawer from './HelpDrawer';
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
function Breadcrumb({ title, help_code='' }) {
    
  const helpText = JSON.parse(useSelector(selectHelpTexts));
    const theme = useTheme();
    const [language, setLanguage] = useState('en');
    const [helpContent, setHelpContent] = useState(help_code === '' ? '' :helpText[help_code][language]);
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
      setLanguage('en');
      setHelpContent(helpText[help_code]['en'])
    };

    const languageChange = async (event) => {
      console.log(event.target.value)
      //setLanguage(event.target.value);
      setHelpContent(helpText[help_code][event.target.value])
    };

    return (
        <Card
            sx={{
                marginBottom: 2,
                border: '1px solid',
                borderColor: theme.palette.primary[200] + 75,
                background: theme.palette.background.default
            }}
        >
            <Box sx={{ p: 1.5 }}>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item >
                        <Typography variant="h3" sx={{ fontWeight: 500 }}>
                            {title}
                            
                        </Typography>
                    </Grid>
                  {help_code !== '' &&  <Grid item >
                    
                        <HelpDrawer title={title} help_code={help_code}/>
                     </Grid> }
                </Grid>
            </Box>
            
        </Card>
    );
}

export default Breadcrumb;
